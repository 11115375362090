import './Spinner.css'

const Spinner = () => {
  return (
    <div class="container">
      <div class="any-element animation is_loading">&nbsp;</div>

      {/* <div class="any-element animation--double is_loading">&nbsp;</div>

        <div class="any-element animation--striped is_loading">&nbsp;</div>

        <div class="any-element animation--dial is_loading">&nbsp;</div> */}
    </div>
  )
}

export default Spinner
