import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.footer`
  height: 100px;
  background-color: #2d3250;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 50px;
  align-items: center;
  @media (max-width: 768px) {
    padding: 10px 20px;
    width: 100%;
    justify-content: center;
  }
`
export const Column = styled.div`
  display: flex;
  height: 100%;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`

export const FooterLink = styled(Link)`
  text-decoration: none;
  color: #ffffffa0;
  font-size: 10px;
  @media (max-width: 768px) {
    font-size: 8px;
  }
`
