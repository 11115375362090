import { Container, LanguageList, LanguageListItem, CurrentLanguage, Icon } from './LanguageSwitcher.styled'
import { MdArrowDropDown, MdLanguage } from 'react-icons/md'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSwitcher = ({ dark }) => {
  const [isOpenLanguageSwitcher, setIsOpenLanguageSwitcher] = useState(false)
  const { i18n } = useTranslation()

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setIsOpenLanguageSwitcher(false)
  }

  return (
    <Container dark={dark}>
      <CurrentLanguage dark={dark}>{i18n.language}</CurrentLanguage>
      <Icon onClick={() => setIsOpenLanguageSwitcher(true)} dark={dark}>
        <MdLanguage className="earth" />
        <MdArrowDropDown />
      </Icon>
      {isOpenLanguageSwitcher && (
        <LanguageList dark={dark}>
          <LanguageListItem onClick={() => changeLanguage('pl')}>PL</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('en')}>EN</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('it')}>IT</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('es')}>ES</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('de')}>DE</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('fr')}>FR</LanguageListItem>
          <LanguageListItem onClick={() => changeLanguage('nl')}>NL</LanguageListItem>
        </LanguageList>
      )}
    </Container>
  )
}

export default LanguageSwitcher
