import styled from 'styled-components'

export const Container = styled.div`
  background-color: #2d3250;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Content = styled.div`
  padding: 32px 24px;
  background-color: #676f9d;
  border-radius: 5px;
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Separator = styled.div`
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  font-size: 12px;
  color: white;
  .line {
    height: 0px;
    width: 100%;
    border-top: 1px solid white;
  }
  p {
    width: fit-content;
  }
`
export const Button = styled.div`
  background-color: #f9b17a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  padding: 8px;
  cursor: pointer;
  :hover {
    opacity: 70%;
  }
  border-radius: 5px;
  width: 100%;
`
export const Text = styled.p`
  margin-bottom: 35px;
  width: 60%;
  font-weight: 600;
  text-align: center;
  color: white;
`
