import { Container, Background, Content } from './Modal.styled'

const Modal = ({ children, closeModal }) => {
  return (
    <Container>
      <Background onClick={closeModal} />
      <Content>{children}</Content>
    </Container>
  )
}

export default Modal
