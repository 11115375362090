import styled from 'styled-components'

export const Title = styled.p``
export const AppList = styled.ul`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
`
export const AppListItem = styled.li`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  gap: 0px;
  width: 300px;
  @media (max-width: 768px) {
    width: 150px;
    height: 30px;
  }
  background: hsla(232, 23%, 34%, 1);
  background: linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);

  background: -moz-linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);

  background: -webkit-linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);
  -webkit-box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);
  -moz-box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);
  box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);

  border-radius: 5px;
  svg {
    height: 40px;
    margin: 10px 0px 10px 15px;
    width: 40px;
    color: #f9b17a;
    @media (max-width: 768px) {
      display: none;
    }
  }
`
export const AppInfo = styled.div`
  width: fit-content;
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
  justif-content: space-between;
  gap: 5px;
  @media (max-width: 768px) {
    font-size: 8px;
  }
  p {
    &:nth-child(2n) {
      font-size: 9px;
      opacity: 60%;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`

export const AddApp = styled.div`
  height: 100%;
  background-color: #626997;
  box-shadow: 1px 1px 9px 3px rgba(66, 71, 105, 1);
  min-width: 45px;
  max-width: 45px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  span {
    color: white;
    font-size: 15px;
    transition: all 0.1s ease-in-out;
  }
  :hover {
    span {
      color: #c2e812;
      font-size: 30px;
    }
  }
  @media (max-width: 768px) {
    min-width: 30px !important;
    max-width: 30px !important;
  }
`
