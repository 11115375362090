import './Loading.css'
const Loading = () => {
  return (
    <div className="box">
      <div class="container">
        <div class="loader">
          <div class="mask"></div>
          <div class="mask2"></div>
        </div>
      </div>
    </div>
  )
}

export default Loading
