import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
`

export const Content = styled.div`
  height: calc(100% - 170px);
  width: 100%;
  background-color: #2d3250;
  color: white;
  padding: 50px;
`
