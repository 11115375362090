import styled from 'styled-components'

export const Content = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  width: 100%;

  margin: ${(props) => props.margin && props.margin};
  @media (max-width: 768px) {
    padding: 20px 5px 20px 5px;
  }
`
export const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: #2d3250;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 10px;
  }
`
export const DetailsBox = styled.div`
  margin-top: 30px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 5px 20px 5px;
  position: relative;
  overflow: hidden;
  gap: 10px;
  input {
    width: 40%;
    padding: 10px 5px;
    font-size: 10px;
    color: grey;
    border-radius: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px solid #b2b6cf;
    box-shadow: 1px 1px 3px 0px rgba(218, 218, 218, 1);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 5px;
  }
`
export const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 500;
  }
`

export const Table = styled.table`
  box-shadow: 1px 1px 7px 0px rgba(218, 218, 218, 1);
  border: none;

  border-collapse: collapse;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-radius: 5px;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 768px) {
      font-size: 10px;
      max-width: 100%;
    }
    svg {
      color: #f9b17a !important;
      height: 15px !important;
      width: 15px !important;
    }
  }
  #icon {
    @media (max-width: 768px) {
      height: 10px;
      width: 10px;
    }
  }

  th {
    text-align: center;
    height: 35px;
    padding: 10px 50px 10px 50px;
    font-size: 10px;
    border: none;
    background-color: #f4f6f7;
    font-size: 14px !important;

    border-right: 1px solid white;
    @media (max-width: 768px) {
      padding: 0;
      font-size: 26px !important;
    }
  }

  tr {
    border-bottom: 1px solid #f4f6f7 !important;
    border-left: 1px solid #f4f6f7 !important;
    border-right: 1px solid #f4f6f7 !important;
    td {
      text-align: center;
      padding: 10px 50px 10px 50px;
      font-size: 12px !important;
      border-right: 1px solid #f4f6f7 !important;

      &:nth-child(4n) {
        border: none !important;
      }
    }
  }
`
