import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1500px;
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 25px;
  }
`

export const Title = styled.h3`
  color: #2d3250;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  line-height: 1.5;
  span {
    font-weight: 700;
    font-size: 22px;
  }
`

export const Box = styled.div`
  border-bottom: 1px solid #b0b2be;
  padding-bottom: 30px;
  margin-bottom: 30px;
  width: 100%;
  div {
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: flex-end;
    padding: 3px 0;
    h5 {
      margin-left: 20px;
      font-size: 20px;
      color: #b0b2be;
    }
  }
`
export const BoxTitle = styled.div`
  margin: 10px 0;
  font-size: 22px;
  font-weight: 500;
`

export const Section = styled.div`
  h3 {
    font-size: 20px;
    color: #b0b2be;
  }
  img {
    height: 300px;
    margin: 50px;
    @media (max-width: 768px) {
      margin: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  p {
    margin-top: 10px;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 12px;
    color: #b0b2be;
    line-height: 1.8;
    padding: 10px;
  }
`
export const SectionTitle = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: flex-end;
  margin-bottom: 20px;
  font-weight: 500;

  padding: 3px 0;
  h5 {
    font-size: 20px;
    color: #b0b2be;
    font-weight: 600;
  }
`
