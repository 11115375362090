import { Subtitle, Button, AddressLine } from '../AddressDetails.styled'
import { useState } from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { FiSave } from 'react-icons/fi'
import { BiEdit } from 'react-icons/bi'
import Spinner from '../../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'
const CompanyData = ({ address, updateCompanyAddressMutation, getAddressQuery, formMethods }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const { handleSubmit, register, getValues } = formMethods
  const { t } = useTranslation()
  const onSubmit = () => {
    updateCompanyAddressMutation.mutate({
      ...address,
      ...getValues('companyData'),
    })
    setTimeout(() => {
      setIsEditMode(false)
    }, 500)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Subtitle>
        {t('pages.billing.company-address')}
        {isEditMode.companyData ? (
          <p>
            <Button
              red
              onClick={(e) => {
                e.preventDefault()
                setIsEditMode((prev) => ({
                  ...prev,
                  companyData: false,
                }))
              }}
            >
              <RiCloseFill />
              {t('pages.billing.buttons.cancel')}
            </Button>
            <Button green type="submit">
              {updateCompanyAddressMutation.isLoading ? (
                <p>
                  {t('pages.apps.install-app.loading')}...
                  <Spinner />
                </p>
              ) : (
                <p>
                  <FiSave />
                  {t('pages.billing.buttons.save')}
                </p>
              )}
            </Button>
          </p>
        ) : (
          <BiEdit
            onClick={(e) =>
              setIsEditMode((prev) => ({
                ...prev,
                companyData: true,
                clientData: false,
              }))
            }
          />
        )}
      </Subtitle>
      {getAddressQuery.isLoading && (
        <p>
          {t('pages.apps.install-app.loading')}... <Spinner />
        </p>
      )}
      {!getAddressQuery.isLoading && (
        <>
          <AddressLine>
            <span>{t('pages.billing.company-name')}:</span>
            {isEditMode.companyData ? <input {...register('companyData.companyName')} /> : <span>{address.companyName}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.address-line')} 1:</span>
            {isEditMode.companyData ? <input {...register('companyData.addressLine1')} /> : <span>{address.addressLine1}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.address-line')} 2:</span>
            {isEditMode.companyData ? <input {...register('companyData.addressLine2')} /> : <span>{address.addressLine2}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.address-line')} 3:</span>
            {isEditMode.companyData ? <input {...register('companyData.addressLine3')} /> : <span>{address.addressLine3}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.post-code')}:</span>
            {isEditMode.companyData ? <input {...register('companyData.postCode')} /> : <span>{address.postCode}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.city')}:</span>
            {isEditMode.companyData ? <input {...register('companyData.city')} /> : <span>{address.city}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.country-code')}:</span>
            {isEditMode.companyData ? <input {...register('companyData.countryISO2')} /> : <span>{address.countryISO2}</span>}
          </AddressLine>
          <AddressLine>
            <span>{t('pages.billing.vat-code')}:</span>
            {isEditMode.companyData ? <input {...register('companyData.vatCode')} /> : <span>{address.vatCode}</span>}
          </AddressLine>
        </>
      )}
    </form>
  )
}

export default CompanyData
