import { AddressElement, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import axios from 'axios'
import { useQuery } from 'react-query'

import { useState, useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { getAmountDue } from '../../../services/address'
import { useTranslation } from 'react-i18next'
import { Title } from '../../DataCentre/DataCentre.styled'
import Spinner from '../../../components/Spinner/Spinner'

const CARD_OPTIONS = {
  style: {
    base: {
      color: '#2d3250',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '12px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#2d3250f9' },
      '::placeholder': { color: '#2d32506f' },
    },
  },
}

const StripeForm = () => {
  const {
    user: { token },
  } = useContext(AuthContext)
  const [isSuccess, setIsSuccess] = useState('')
  const [isError, setIsError] = useState('')
  const [amountDue, setAmountDue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const amountDueQuery = useQuery({
    queryFn: () => getAmountDue(token),
    queryKey: ['amount_due'],
    onSuccess: (res) => {
      const total = !res.data[0] ? '0' : `${res.data[0]?.amount} ${res.data[0]?.currency}`
      setAmountDue(total)
    },
  })

  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()

  const savePaymentDataToLocalStorage = (paymentData) => {
    localStorage.setItem('address', JSON.stringify(paymentData.billing_details.address))
    localStorage.setItem('name', encodeURIComponent(JSON.stringify(paymentData.billing_details.name)))
    localStorage.setItem('phone', encodeURIComponent(JSON.stringify(paymentData.billing_details.phone)))
  }

  const getPaymentData = async () => {
    const {
      value: { name, phone, address },
    } = await elements?.getElement(AddressElement).getValue()

    const card = await elements?.getElement(CardNumberElement, CardCvcElement, CardExpiryElement)
    return { card, name, phone, address }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const { card, name, phone, address } = await getPaymentData()

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: { name, phone, address },
      })
      savePaymentDataToLocalStorage(paymentMethod)

      if (!error) {
        try {
          const { id } = paymentMethod
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/Supplier/Payment`,
            {
              amount: amountDue,
              id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (response.data.success) {
            setIsSuccess('Successful Payment')
            setIsLoading(false)
          }
        } catch (error) {
          setIsError('Payment failed. Try again later.')
          setIsLoading(false)
          return
        }
      } else {
        setIsError(error.message)
        setIsLoading(false)
        return
      }
    } catch (error) {
      setIsError(error.message)

      setIsLoading(false)
      return
    }
    setIsLoading(false)
  }

  return (
    <div>
      <form style={{ marginTop: 20, width: 500, maxWidth: '100%' }} onSubmit={handleSubmit}>
        <Title>{t('pages.payments.address')}</Title>
        <br />
        <fieldset style={{ border: 'none' }}>
          <AddressElement
            options={{
              defaultValues: {
                name: decodeURIComponent(localStorage.getItem('name')).slice(1, -1) ?? '',
                address: JSON.parse(localStorage.getItem('address')) ?? {},
                phone: decodeURIComponent(localStorage.getItem('phone')) ?? '',
              },
              mode: 'billing',
              fields: {
                phone: 'always',
              },
            }}
          />
        </fieldset>
        <br />
        <Title>{t('pages.payments.payment-method')}</Title>
        <br />

        <fieldset style={{ border: '1px solid grey', marginTop: 10, padding: 5 }}>
          <CardNumberElement options={CARD_OPTIONS} />
        </fieldset>
        <fieldset style={{ border: 'none', marginTop: 10, display: 'flex', gap: 10, width: '100%' }}>
          <div style={{ width: '50%', border: '1px solid grey', padding: 5 }}>
            <CardCvcElement options={CARD_OPTIONS} />
          </div>
          <div style={{ width: '50%', border: '1px solid grey', padding: 5 }}>
            <CardExpiryElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        <br />
        <p>
          {t('pages.payments.amount-due')}: {amountDue}
        </p>
        <h4 style={{ padding: '10px 0', borderBottom: '1px solid #2d3250' }}></h4>
        <button
          type="submit"
          disabled={amountDue.split(' ')[0] === 0}
          style={{
            textTransform: 'uppercase',
            fontWeight: 600,
            fontSize: 14,
            color: 'white',
            marginTop: 20,
            width: '100%',
            padding: 10,
            backgroundColor: '#f9b17a',
            border: 'none',
            borderRadius: 5,
            cursor: 'pointer',
          }}
          className="submit-button"
        >
          {isLoading ? (
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <p>Loading... </p>
              <div style={{ width: 'fit-content', height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <div class="loadercontainer">
                  <div class="loader-any-element loader-animation loader-is_loading">&nbsp;</div>
                </div>
              </div>
            </p>
          ) : (
            t('pages.payments.pay')
          )}
        </button>
        {isSuccess && <p style={{ color: 'green', padding: '10px 0', fontWeight: 600 }}>{isSuccess}</p>}
        {isError && <p style={{ color: 'red', padding: '10px 0', fontWeight: 600 }}>{isError}</p>}
      </form>
    </div>
  )
}

export default StripeForm
