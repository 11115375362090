import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  background-color: #2d3250;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  height: 70px;
  @media (max-width: 768px) {
    display: none;
  }
`
export const NavList = styled.ul`
  display: flex;
  width: 100%;
  max-width: 1500px;

  list-style: none;
  justify-content: space-between;
  .logo {
    display: flex;
    items-align: center;
    gap: 10px;
    color: white;
    font-size: 20px;
    img {
      height: 25px;
      color: white;
    }
  }
  .list {
    display: flex;
    gap: 40px;
  }
`
export const NavItem = styled.li`
  text-decoration: none;
`
export const NavLink = styled(Link)`
  text-decoration: none;
  border-bottom: ${(props) => (props.$active ? '2px solid #f9b17a' : 'none')};
  color: ${(props) => (props.$active ? 'white' : '#ffffffa0')};
  padding: 5px 10px;
`
