import styled from 'styled-components'

export const Title = styled.h4`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 15px;
`
export const ClientList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  margin-top: 20px;
  padding-left: 25px;
  flex-direction: column;
`

export const ClientListItem = styled.li`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 50px;
  padding: 0 10px;
  input {
    width: 10px;
    height: 10px;
  }
  svg {
    height: 10px;
    width: 10px;
  }
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 1px 2px 6px -1px rgba(214, 214, 214, 1);
  -moz-box-shadow: 1px 2px 6px -1px rgba(214, 214, 214, 1);
  box-shadow: 1px 2px 6px -1px rgba(214, 214, 214, 1);
  cursor: pointer;
  border-left: 6px solid #676f9d;
  font-size: 12px;
  color: hsla(231, 28%, 25%, 1);
  &#active {
    border-left: 6px solid #c2e812 !important;
    color: #465502;
  }
  &#inactive {
    border-left: 6px solid #ec465a !important;
    color: #6d0511;
  }
`
export const CopyIndicator = styled.div`
  background-color: hsla(230, 66%, 80%, 1);
  color: white;
  font-weight: 600;
  font-size: 10px;
  padding: 5px;
  position: fixed;
  top: 10px;
  left: 40%;
  border-radius: 6px;
`
