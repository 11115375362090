import styled from 'styled-components'

export const Content = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  max-width: 100%;
  margin: ${(props) => props.margin && props.margin};
  p,
  a {
    @media (max-width: 768px) {
      font-size: 12px;
      width: fit-content;
    }
  }
`
export const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: #2d3250;
`
export const Box = styled.div`
  margin: ${(props) => props.margin && props.margin};
  margin-bottom: 20px;
`
export const ApiKey = styled.p`
  max-width: 100%;
  overflow-wrap: break-word;
  margin-top: 10px;
  font-size: 14px;
`

export const AppList = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const AppTitle = styled.h4`
  color: white;
  font-size: 12px;
`
export const AppInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
`

export const AppItem = styled.div`
  height: 70px;
  cursor: pointer;
  width: 220px;
  border: 1px solid #424769;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
  position: relative;
  background-color: #424769;

  svg {
    height: 40px;
    width: 40px;
    color: #f9b17a;
  }
  #paid {
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 2;
    height: 25px;
    width: 25px;
    color: #ec465a;
  }
  #log {
    position: absolute;
    right: 5px;
    bottom: 5px;
    curosr: pointer;
    height: 20px;
    width: 20px;
    color: #c2e812;
  }
  #bin-icon {
    color: #ec465a;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    curosr: pointer;
    height: 20px;
    width: 20px;
  }
`
