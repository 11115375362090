import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthContextProvider from './context/AuthContext'
import { I18nextProvider } from 'react-i18next'

import i18next from './i18n'
import Spinner from './components/Spinner/Spinner'

const queryClient = new QueryClient({})
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18next}>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </AuthContextProvider>
    </I18nextProvider>
  </Suspense>
)
