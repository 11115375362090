import { useTranslation } from 'react-i18next'
import { Card, Title, Subtitle, Icon, Divider, Container, Text, SubText } from './PricingPlanCard.styled'
import { HiCheck } from 'react-icons/hi'
import { RiCloseLine } from 'react-icons/ri'
const PricingPlanCard = ({ pricingPlan, isActive }) => {
  const { t } = useTranslation()

  return (
    <Card isActive={isActive}>
      <Icon>{pricingPlan.icon}</Icon>
      <Title>{pricingPlan.title}</Title>
      <Subtitle>{pricingPlan.subtitle}</Subtitle>
      <Divider />
      <Container>
        <div>
          <Text>{t('pages.pricing.number-of-networks')}</Text>
          <SubText>{pricingPlan.networks}</SubText>
        </div>
        <div>
          <Text>{t('pages.pricing.number-of-customers')}</Text>
          <SubText>{pricingPlan.max_clients}</SubText>
        </div>
        <div>
          <Text>{t('pages.pricing.support')}</Text>
          <SubText green={pricingPlan.support === 'Y'} red={pricingPlan.support === 'N'}>
            {pricingPlan.support === 'Y' ? <HiCheck style={{ color: '#c2e812 ' }} /> : <RiCloseLine style={{ color: '#ec465a ' }} />}
          </SubText>
        </div>
        <div>
          <Text>{t('pages.pricing.basic-support')}</Text>
          <SubText green={pricingPlan.no_sla_support === 'Y'} red={pricingPlan.no_sla_support === 'N'}>
            {pricingPlan.no_sla_support === 'Y' ? <HiCheck style={{ color: '#c2e812 ' }} /> : <RiCloseLine style={{ color: '#ec465a ' }} />}
          </SubText>
        </div>
        <div>
          <Text>{t('pages.pricing.premium-support')}</Text>
          <SubText green={pricingPlan.sla_support === 'Y'} red={pricingPlan.sla_support === 'N'}>
            {pricingPlan.sla_support === 'Y' ? <HiCheck style={{ color: '#c2e812 ' }} /> : <RiCloseLine style={{ color: '#ec465a ' }} />}
          </SubText>
        </div>
        <div>
          <Text>{t('pages.pricing.custom-development')}</Text>
          <SubText>{pricingPlan.extra === 'Y' ? <HiCheck style={{ color: '#c2e812 ' }} /> : <RiCloseLine style={{ color: '#ec465a ' }} />}</SubText>
        </div>
      </Container>
    </Card>
  )
}

export default PricingPlanCard
