import SecondaryLayout from '../../components/Layout/SecondaryLayout/SecondaryLayout'

import PlanSummary from './PlanSummary/PlanSummary'
import AddressDetails from './AddressDetails/AddressDetails'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
const Billing = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  return (
    <SecondaryLayout>
      <PlanSummary />
      <AddressDetails />
    </SecondaryLayout>
  )
}

export default Billing
