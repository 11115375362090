import { Routes as Routing, Route, useLocation } from 'react-router-dom'
import PrivateRoute from './PrivateRoutes'
import Home from '../pages/Home/Home'
import Login from '../pages/Login/Login'
import Pricing from '../pages/Pricing/Pricing'
import DataCentre from '../pages/DataCentre/DataCentre'
import Apps from '../pages/Apps/Apps'
import Network from '../pages/Network/Network'
import Billing from '../pages/Billing/Billing'
import Unauthorized from '../pages/Error/Unauthorized'
import QuickGuide from '../pages/QuickGuide/QuickGuide'
import Payments from '../pages/Payments/Payments'

const Routes = () => {
  const location = useLocation()

  return (
    <Routing location={location}>
      <Route path="/" exact element={<Home />} />
      <Route path="/sign-in" exact element={<Login />} />
      <Route path="/pricing" exact element={<Pricing />} />
      <Route path="/quick_guide" exact element={<QuickGuide />} />

      <Route path="unauthorized" exact element={<Unauthorized />} />
      <Route
        path="/data-centre"
        exact
        element={
          <PrivateRoute>
            <DataCentre />
          </PrivateRoute>
        }
      />
      <Route
        path="/apps"
        exact
        element={
          <PrivateRoute>
            <Apps />
          </PrivateRoute>
        }
      />

      <Route
        path="/billing"
        exact
        element={
          <PrivateRoute>
            <Billing />
          </PrivateRoute>
        }
      />
      <Route
        path="/payments"
        exact
        element={
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        }
      />
      <Route
        path="/network"
        exact
        element={
          <PrivateRoute>
            <Network />
          </PrivateRoute>
        }
      />
    </Routing>
  )
}

export default Routes
