import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const Box = styled.div`
  .secondary {
    font-size: 13px;
    line-height: 1.5;
    width: 70%;
    color: #676f9d;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 12px;
    }
  }
`
export const DetailsBox = styled.div`
  margin-bottom: 20px;
`
export const Text = styled.p`
  display: flex;
  font-size: 14px;
  gap: 10px;
  width: 100%;

  padding: 3px 0;
  span {
    display: block;
    &:nth-child(2n) {
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
    line-height: 22px;
  }
`

export const CustomLink = styled(Link)`
  background-color: #f9b17a;
  text-decoration: none;
  color: white;
  padding: 5px 7px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 5px;
    margin-right: 5px;
    border-radius: 4px;
  }
`
export const Table = styled.table`
  width: 100%;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  thead {
    width: 100%;
    @media (max-width: 768px) {
      font-size: 10px;;
    }
    tr {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      background-color: #676f9d;
      color: white;
      padding: 10px;

      th {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 15%;
        @media (max-width: 768px) {
          width: 25%;
          padding: 10px 0;
        }
        &:first-child {
          width: 30px;
          @media (max-width: 768px) {
            width: 5px;
          }
        }
       
        
      }
    }
  }
  tbody {
    width: 100%;
    
    tr {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      background-color: #f3f3f7;
      cursor: pointer;
      border: 0.5px solid white;

      td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 15%;
        padding: 8px 0;
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 10px;
          width: 25%;
        }
        &:first-child {
          width: 30px;
          @media (max-width: 768px) {
            width: 30px;
          }
        }
        
        svg {
          cursor: pointer;
          font-size: 25px;
          color: #f9b17a;
          
        }
      }
    }
  }

  }
`
export const SubTable = styled.table`
  width: 100%;
  margin-top: 10px;
  padding-left: 30px;
  @media (max-width: 768px) {
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  thead {
    width: 100%;
    background-color: #f3f3f7;
    color: #676f9d;
    tr {
      font-size: 8px;
      width: 100%;
      padding: 10px;
      display: flex;
      aling-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        font-size: 6px;
        padding: 5px;
        justify-content: space-between;
      }
      th {
        width: 15%;
        display: flex;

        align-items: center;
        color: #676f9d;

        justify-content: center;
        @media (max-width: 768px) {
          width: fit-content;
          justify-content: space-between;
        }
      }
    }
  }
  tbody {
    width: 100%;
    tr {
      font-size: 8px;
      width: 100%;
      display: flex;
      aling-items: center;
      justify-content: flex-start;
      padding: 7px;
      border-bottom: 1px solid #f3f3f7;
      border-left: 1px solid #f3f3f7;
      border-right: 1px solid #f3f3f7;
      padding-left: 25px;
      @media (max-width: 768px) {
        font-size: 8px;
        padding: 0px;
        padding-left: 5px;
        justify-content: space-between;
      }
      td {
        width: 15%;
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          font-size: 6px;
        }
      }
    }
  }
`
