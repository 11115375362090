import { Box, DetailsBox, SubTable, Text, Table, CustomLink } from './PlanSummary.styled'
import Card from '../../../components/Card/Card'
import { useQuery } from 'react-query'
import { getBillingData } from '../../../services'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import Spinner from '../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'
import { getBillingHistory, getCurrentBalance } from '../../../services/address'
import { MdArrowDropDown } from 'react-icons/md'

const PlanSummary = () => {
  const [billingData, setBillingData] = useState({})
  const [billingHistory, setBillingHistory] = useState([])
  const [currentBalance, setCurrentBalance] = useState('')
  const [openRows, setOpenRows] = useState([])
  const toggleRow = (index) => {
    const newOpenRows = [...openRows]
    newOpenRows[index] = !newOpenRows[index]
    setOpenRows(newOpenRows)
  }

  const {
    user: { token },
  } = useContext(AuthContext)
  const { t } = useTranslation()

  function formatNumberWithTwoDecimals(number) {
    const formattedNumber = number.toFixed(2)
    return parseFloat(formattedNumber)
  }

  const billingDataQuery = useQuery({
    queryFn: () => getBillingData(token),
    queryKey: ['billng_data'],
    onSuccess: (res) => {
      setBillingData((prev) => ({
        ...res.data,
      }))
    },
  })

  const currentBalanceQuery = useQuery({
    queryFn: () => getCurrentBalance(token),
    queryKey: ['current_balance'],
    onSuccess: (res) => {
      const total = !res.data[0] ? '0' : `${res.data[0]?.amount} ${res.data[0]?.currency}`
      setCurrentBalance(total)
    },
  })

  const billingHistoryQuery = useQuery({
    queryFn: () => getBillingHistory(token),
    queryKey: ['billing_history'],
    onSuccess: (res) => {
      setBillingHistory([...res.data])
    },
  })

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    return date.toLocaleDateString('en-GB', options).replaceAll('/', '.')
  }

  return (
    <Card>
      <Box>
        {billingDataQuery.isLoading && (
          <p>
            {t('pages.apps.install-app.loading')}...
            <Spinner />
          </p>
        )}
        {!billingDataQuery.isLoading && !billingDataQuery.isError && (
          <DetailsBox>
            <Text>
              <span> {t('pages.billing.status')}: </span>
              <span>{billingData.status}</span>
            </Text>
            <Text>
              <span>{t('pages.billing.current-billing-period')}:</span>
              <span>{billingData.currentPeriod}</span>
            </Text>
            <Text>
              <span>{t('pages.billing.next-payment-date')}:</span>
              <span>{billingData.paymentDate}</span>
            </Text>
            <Text>
              <span>{t('pages.billing.total-amount')}: </span>
              <span>{currentBalance}</span>
            </Text>
          </DetailsBox>
        )}
        {!billingDataQuery.isLoading && billingDataQuery.isError && <p>{t('pages.apps.install-app.error')}</p>}
        <Text className="secondary">{t('pages.billing.total-amount-explanation')}</Text>
      </Box>
      {billingHistory.length > 0 && (
        <Box style={{ margin: '30px 0 0 0' }}>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>{t('pages.billing.table.total-gross')}</th>
                <th>{t('pages.billing.table.Currency')} </th>
                <th>{t('pages.billing.table.isPaid')}</th>
                <th style={{ display: 'flex', justifyContent: 'center', margin: '0 10px 0 10px' }}>{t('pages.billing.table.invoice-link')}</th>
                <th style={{ margin: '0 10px 0 10px' }}>{t('pages.billing.table.date')}</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory?.map((el, index) => (
                <>
                  <tr
                    key={index}
                    onClick={() => {
                      toggleRow(index)
                    }}
                  >
                    <td>
                      <MdArrowDropDown
                        style={{
                          transition: 'transform 0.3s ease',
                          transform: openRows[index] ? 'rotate(0deg)' : 'rotate(-90deg)',
                        }}
                      />
                    </td>
                    <td>{formatNumberWithTwoDecimals(el?.totalGross)}</td>
                    <td>{el?.currency}</td>
                    <td>
                      {el?.isPaid === 1 && t('pages.billing.table.paid')}
                      {el?.isPaid === 0 && t('pages.billing.table.not-paid')}
                      {el?.isPaid !== 1 && el?.isPaid !== 0 && t('pages.billing.table.in-progress')}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center', margin: '0 10px 0 10px' }}>{el?.invoiceLink === null ? '-' : el?.invoiceLink}</td>
                    <td style={{ margin: '0 10px 0 10px' }}>{formatDate(el?.date)}</td>
                    <td>
                      <CustomLink to="/payments"> {t('pages.payments.pay')}</CustomLink>
                    </td>
                  </tr>
                  {openRows[index] && el?.billingLines?.length > 0 && (
                    <SubTable>
                      <thead>
                        <tr>
                          <th>{t('pages.billing.subtable.product-name')}</th>
                          <th>{t('pages.billing.subtable.unit-price-net')}</th>
                          <th>{t('pages.billing.subtable.quantity')}</th>
                          <th>{t('pages.billing.subtable.price-net')}</th>
                          <th>{t('pages.billing.subtable.discount')}</th>
                          <th>{t('pages.billing.subtable.tax-rate')}</th>
                          <th>{t('pages.billing.subtable.price-gross')}</th>
                          <th>{t('pages.billing.subtable.currency')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {el.billingLines.map((billingLine) => (
                          <tr>
                            <td>{billingLine.productName}</td>
                            <td>{billingLine.unitPriceNet}</td>
                            <td>{billingLine.quantity}</td>
                            <td>{billingLine.priceNet}</td>
                            <td>{billingLine.discount}</td>
                            <td>{billingLine.taxRate}</td>
                            <td>{formatNumberWithTwoDecimals(billingLine.priceGross)}</td>
                            <td>{billingLine.currency}</td>
                          </tr>
                        ))}
                      </tbody>
                    </SubTable>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        </Box>
      )}
    </Card>
  )
}

export default PlanSummary
