import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'

import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/translations.json'
import de from './locales/de/translations.json'
import es from './locales/es/translations.json'
import fr from './locales/fr/translations.json'
import it from './locales/it/translations.json'
import nl from './locales/nl/translations.json'
import pl from './locales/pl/translations.json'

const resources = {
  en: { translation: en },
  pl: { translation: pl },
  it: { translation: it },
  es: { translation: es },
  de: { translation: de },
  nl: { translation: nl },
  fr: { translation: fr },
}
i18n
  .use(Backend)

  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    debug: false,
    supportedLngs: ['pl', 'de', 'es', 'nl', 'en', 'fr', 'it'],
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
