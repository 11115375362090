import styled from 'styled-components'

export const PaymentElement = styled.div`
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 10px !important;
  }
  .link {
    @media (max-width: 768px) {
      font-size: 8px !important;
    }
  }
`
