import { Container, List, ListItem } from './Sidebar.styled'
import { TbDatabaseExport, TbApps } from 'react-icons/tb'
import { FaFileInvoiceDollar, FaNetworkWired } from 'react-icons/fa'
import { AuthContext } from '../../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlinePayment } from 'react-icons/md'

const Sidebar = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { setUser, setIsLoggedIn } = useContext(AuthContext)
  return (
    <Container>
      <List>
        <div className="flex">
          <div>
            <ListItem to="/data-centre" $active={location.pathname === '/data-centre'}>
              <TbDatabaseExport className="icon" />
              <p>{t('sidebar.data-centre')}</p>
            </ListItem>
            <ListItem to="/billing" $active={location.pathname === '/billing'}>
              <FaFileInvoiceDollar className="icon" />
              <p>{t('sidebar.billing')}</p>
            </ListItem>
            <ListItem to="/apps" $active={location.pathname === '/apps'}>
              <TbApps className="icon" />
              <p>{t('sidebar.apps')}S</p>
            </ListItem>
            <ListItem to="/network" $active={location.pathname === '/network'}>
              <FaNetworkWired className="icon" />
              <p>{t('sidebar.networks')}</p>
            </ListItem>
            <ListItem to="/payments" $active={location.pathname === '/payments'}>
              <MdOutlinePayment className="icon" />
              <p>{t('sidebar.payments')}</p>
            </ListItem>
          </div>
          <div>
            <ListItem
              to="/"
              onClick={(e) => {
                setUser({
                  token: '',
                  userName: '',
                })
                setIsLoggedIn(false)
              }}
            >
              <FiLogOut className="icon" />
              <p>{t('sidebar.logout')}</p>
            </ListItem>
          </div>
        </div>
      </List>
    </Container>
  )
}

export default Sidebar
