import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.dark ? '#2d3250' : '#ffffffa0')};
  margin-right: 20px;
  font-size: 12px;
  height: 100%;
`
export const CurrentLanguage = styled.p`
  padding: 0px 5px;
  text-transform: uppercase;
  color: ${(props) => (props.dark ? '#2d3250' : '#ffffffa0')};
`
export const Icon = styled.i`
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  color: ${(props) => (props.dark ? '#2d3250' : '#ffffffa0')};
  .earth {
    @media (max-width: 768px) {
      display: none;
    }
  }
`
export const LanguageList = styled.ul`
  position: absolute;
  top: 30px;
  list-style: none;
  background-color: #f9b17a;
  width: 100px;
  border-radius: 6px;
`
export const LanguageListItem = styled.li`
  padding: 5px;
  border-bottom: 1px solid #2d3250;
  cursor: pointer;
  color: #2d3250;
`
