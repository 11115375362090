import { useState, useContext } from 'react'
import { Title, AppList, AppListItem, AppInfo, AddApp } from './AvailableApps.styled'
import { MdOutlineLibraryAdd } from 'react-icons/md'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import { useQuery } from 'react-query'
import { getApps } from '../../../services'
import { AuthContext } from '../../../context/AuthContext'
import { MdAdd } from 'react-icons/md'
import Card from '../../../components/Card/Card'
import AppDetails from '../AppDetails/AppDetails'
import Spinner from '../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'

const AvailableApps = () => {
  const [availableApps, setAvailableApps] = useState({
    input: [],
    output: [],
    types: [],
  })
  const [appDetails, setAppDetails] = useState(false)

  const {
    user: { token },
  } = useContext(AuthContext)
  const { t } = useTranslation()

  const availableAppsQuery = useQuery({
    queryKey: ['availableApps'],
    queryFn: () => getApps(token),
    onSuccess: (res) => {
      const type = new Set(res.data.map((el) => el.object))
      const outApps = res.data.filter((app) => app.type === 'OUTPUT')
      const inApps = res.data.filter((app) => app.type === 'INPUT')

      setAvailableApps((prev) => ({
        ...prev,
        input: [...inApps],
        output: [...outApps],
        types: [...type],
      }))
    },
  })
  return (
    <>
      <Card margin="20px 0 0 0">
        <Title
          style={{
            marginTop: 0,
            borderBottom: '1px solid #676f9d',
            paddingBottom: 10,
          }}
        >
          {t('pages.apps.input-apps')}
        </Title>

        {availableApps?.types.map((el, idx) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              borderBottom: idx !== availableApps?.types?.length - 1 ? '1px solid #676f9d' : 'none',
              padding: '10px 0 30px 0',
            }}
          >
            <div
              style={{
                width: '80px',
                fontSize: 12,
              }}
            >
              <p>{el}</p>
            </div>

            <AppList>
              {availableApps.input.map((app) => {
                if (app.object === el) {
                  return (
                    <AppListItem onClick={() => setAppDetails(app)}>
                      {app.price && <RiMoneyDollarCircleFill id="paid" />}
                      <MdOutlineLibraryAdd />
                      <AppInfo>
                        <p>{app.title}</p>
                        <p>{app.description.slice(0, 70)}...</p>
                      </AppInfo>
                      <AddApp>
                        <span>+</span>
                      </AddApp>
                    </AppListItem>
                  )
                }
              })}
            </AppList>
          </div>
        ))}
      </Card>
      <Card margin="20px 0 0 0">
        <Title
          style={{
            marginTop: 0,
            borderBottom: '1px solid #676f9d',
            paddingBottom: 10,
          }}
        >
          {t('pages.apps.output-apps')}
        </Title>
        {availableApps?.types.map((el, idx) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              borderBottom: idx !== availableApps?.types?.length - 1 ? '1px solid #676f9d' : 'none',
              padding: '10px 0 30px 0',
            }}
          >
            <div
              style={{
                width: '80px',
                fontSize: 12,
              }}
            >
              <p>{el}</p>
            </div>

            <AppList>
              {availableApps.output.map((app) => {
                if (app.object === el) {
                  return (
                    <AppListItem onClick={() => setAppDetails(app)}>
                      {app.price && <RiMoneyDollarCircleFill id="paid" />}
                      <MdOutlineLibraryAdd />
                      <AppInfo>
                        <p>{app.title}</p>
                        <p>{app.description.slice(0, 70)}...</p>
                      </AppInfo>
                      <AddApp>
                        <span>+</span>
                      </AddApp>
                    </AppListItem>
                  )
                }
              })}
            </AppList>
          </div>
        ))}
        {appDetails && <AppDetails app={appDetails} setAppDetails={setAppDetails} />}
      </Card>
    </>
  )
}

export default AvailableApps
