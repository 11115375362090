import styled from 'styled-components'

export const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: #2d3250;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
export const Box = styled.div`
  margin: ${(props) => props.margin && props.margin};
  margin-bottom: 20px;
`
export const AddressContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  form {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`
export const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  span {
    &:nth-child(1) {
      display: block;
      width: 150px;
    }
    &:nth-child(2n) {
      display: block;
      font-weight: 500;
    }
  }
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

export const AddressLine = styled.p`
  font-size: 13px;
  font-weight: 400;
  display: flex;
  width: 100%;
  height: 20px;
  span {
    &:nth-child(1) {
      display: block;
      width: 150px;
    }
    &:nth-child(2n) {
      display: block;
      font-weight: 500;
      @media (max-width: 768px) {
        width: calc(100% - 150px);
      }
    }
  }
  @media (max-width: 768px) {
    font-size: 16px;
    height: 40px;
    
  }
  input {
    @media (max-width: 768px) {
      height: 30px;
      width: calc(100% - 150px);
      
  }
`
export const AddressBox = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

export const Subtitle = styled.p`
  border-bottom: 1px solid #2d3250;
  min-width: 100%;
  padding-bottom: 16px;
  margin-bottom: 10px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  gap: 15px;
  svg {
    width: 20px;
    height: 20px;
    color: #f9b17a;
    cursor: pointer;
  }
  p {
    display: flex;
    gap: 5px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    padding-right: 0;
  }
`

export const Button = styled.button`
  width: fit-content;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${(props) => props.green && ' #c2e812'};
  background-color: ${(props) => props.red && '#ec465a;'};
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 6px;
  svg {
    color: white;
    height: 15px;
    width: 15px;
    @media (max-width: 768px) {
      height: 10px;
      width: 10px;
    }
  }
  @media (max-width: 768px) {
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
  }
`
