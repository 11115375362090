import { Layout, Container, Hero, Form, Title, SubmitButton, FormGroup, Input, Left, Right, Subtitle, Content } from './Home.styled'
import Navbar from '../../components/Bars/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import FormError from '../../components/Form/FormError'
import * as patterns from '../../utils/patterns'
import { sendInvitation } from '../../services'
import { useMutation } from 'react-query'
import Description from './Description/Description'
import { htmlEncode } from '../../utils/htmlEncode'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MobileNavbar from '../../components/Bars/MoblieNavbar/MobileNavbar'

const Home = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const mutation = useMutation((email) => sendInvitation(email))

  const onSubmit = (data) => {
    let newData = {
      ...data,
      email: htmlEncode(data.email),
    }
    mutation.mutate(newData)
  }

  const { t } = useTranslation()
  return (
    <Layout>
      <Navbar />
      <MobileNavbar />
      <Content>
        <Container>
          <Hero>
            <Left>
              <Title>{t('pages.dashboard.navbar.hero-title')}</Title>
              <Subtitle>{t('pages.dashboard.navbar.hero-subtitle')}</Subtitle>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Input
                    type="email"
                    placeholder={t('pages.dashboard.navbar.email-address-placeholder')}
                    id="email"
                    name="email"
                    margin="20px 0 10px 0"
                    {...register('email', {
                      required: t('pages.dashboard.navbar.required-field'),
                      pattern: {
                        value: patterns.EMAIL_PATTERN,
                        message: t('pages.dashboard.navbar.email-validation-error'),
                      },
                    })}
                  />
                  <SubmitButton type="submit">{t('pages.dashboard.navbar.get-access-button')}</SubmitButton>
                  <ErrorMessage errors={errors} name="email" render={({ message }) => <FormError message={message} top="40px" left="0" />} />
                  {mutation.isSuccess && <p>{t('pages.dashboard.navbar.success')}</p>}
                </FormGroup>
              </Form>
            </Left>
            <Right>
              <p>So cool tool being built here. It's like Twitter, Dropbox, Meta in one, finally something exciting hapenning in B2B software. Thank you team, keep up good work!</p>
              <p>James Parker </p>
            </Right>
          </Hero>
        </Container>
      </Content>
      <Description />
      <Footer />
    </Layout>
  )
}

export default Home
