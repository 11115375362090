import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
export const Background = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000000c0;
  z-index: 9;
`
export const Content = styled.div`
  z-index: 10;
  background-color: #2d3250;
  padding: 32px 48px;
  height: fit-content;
  width: 500px;
  border-radius: 10px;
  position: relative;
  @media (max-width: 768px) {
    max-width: 90%;
    padding: 32px 20px;
  }
`
