import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  padding-top: 50px;
  padding-cottom: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1500px;
  gap: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const Title = styled.h3`
  color: #2d3250;
  width: 20%;
  padding: 20px;
  font-size: 26px;
  span {
    font-weight: 300;
    font-size: 36px;
  }
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
`
export const FirstPlan = styled.div`
  width: 20%;
  background-color: #a3a8c4;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 50% 100%, 0 66%);
  align-items: center;
  justify-content: center;
  svg {
    color: #f9b17a;
    height: 30px;
    width: 30px;
  }
  p {
    color: white;
    &:nth-child(2n) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    &:nth-child(3n) {
      font-size: 10px;
      border-top: 1px solid white;

      margin: 0 25px;
      padding-top: 20px;

      text-align: center;
      margin-bottom: 10px;
    }
  }
`
export const SecondPlan = styled.div`
  width: 20%;
  background-color: #6c7395;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 50% 100%, 0 66%);
  svg {
    color: #f9b17a;
    height: 30px;
    width: 30px;
  }
  p {
    color: white;
    &:nth-child(2n) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    &:nth-child(3n) {
      font-size: 10px;
      border-top: 1px solid white;

      margin: 0 25px;
      padding-top: 20px;

      text-align: center;
      margin-bottom: 10px;
    }
`
export const ThirdPlan = styled.div`
  width: 20%;
  background-color: #53597b;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 50% 100%, 0 66%);
  svg {
    color: #f9b17a;
    height: 30px;
    width: 30px;
  }
  p {
    color: white;
    &:nth-child(2n) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    &:nth-child(3n) {
      font-size: 10px;
      border-top: 1px solid white;

      margin: 0 25px;
      padding-top: 20px;

      text-align: center;
      margin-bottom: 10px;
    }
`
export const FourthPlan = styled.div`
  width: 20%;
  background-color: #2d3250;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 50% 100%, 0 66%);
  svg {
    color: #f9b17a;
    height: 30px;
    width: 30px;
  }
  p {
    color: white;
    &:nth-child(2n) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    &:nth-child(3n) {
      font-size: 10px;
      border-top: 1px solid white;

      margin: 0 25px;
      padding-top: 20px;

      text-align: center;
      margin-bottom: 10px;
    }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &:nth-child(2n) {
    background-color: #f1f3f4;
    padding: 5px 0px;
  }
  div {
    font-size: 12px;
    padding: 5px 10px;
    width: 20%;
    color: #2d3250;
    &:nth-child(3n) {
      text-align: center;
    }
    &:nth-child(2n) {
      text-align: center;
    }
    &:nth-child(5n) {
      text-align: center;
    }
  }
`
