import styled from 'styled-components'

export const Card = styled.div`
  background-color: #676f9d;
  padding: 30px 20px 10px 20px;
  min-width: 350px !important;
  height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsla(232, 23%, 34%, 1);
  background: linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);

  background: -moz-linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);

  background: -webkit-linear-gradient(90deg, hsla(232, 23%, 34%, 1) 0%, hsla(231, 22%, 51%, 1) 98%);
  -webkit-box-shadow: 3px 3px 17px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 3px 3px 17px -5px rgba(66, 68, 90, 1);
  box-shadow: 3px 3px 17px -5px rgba(66, 68, 90, 1);
  z-index: ${(props) => (props.isActive ? 999 : 9)};
  transform: ${(props) => (props.isActive ? `scale(1.01)` : `scale(0.9)`)};
  opacity: ${(props) => (props.isActive ? '100%' : '70%')};

  transition: ease;
  transition-duration: 0.5s;
`
export const Title = styled.h3`
  color: white;
  font-size: 22px;
`
export const Subtitle = styled.h5`
  color: #f9b17a;
  font-size: 18px;
  text-align: center;
  width: 90%;
  margin-top: 5px;
`
export const Icon = styled.div`
  color: #f9b17a;
  justify-content: center;
  svg {
    width: 50px;
    height: 50px;
  }
`
export const Divider = styled.div`
  width: 60%;
`
export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 30px 0;
  border-top: 0.5px solid white;
  div {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid white;
    padding: 5px 0;
    &:last-child {
      border-bottom: none;
    }
  }
`
export const Text = styled.p`
  width: 65%;
  font-size: 16px;
`
export const SubText = styled.p`
  font-size: 16px;
 
  svg {
    width: 30px;
    height: 30px;
    
  }

  color: ${(props) => props.green && '#c2e812 '}
    color: ${(props) => props.red && '#ec465a '}
`
