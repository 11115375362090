import Modal from '../../../components/Modal/Modal'
import { Content, Title, Text, CloseIcon, ButtonBox, Button } from './AppDetails.styled'
import { ErrorMessage } from '@hookform/error-message'
import * as Form from '../../../components/Form/Form.styled'
import FormError from '../../../components/Form/FormError'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { addApp, getInstalledApps, getInvitedBy, getInvitedUsers, getNetworks } from '../../../services'
import Spinner from '../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'

const AppDetails = ({ app, setAppDetails }) => {
  const params = app.params.split('###')
  const [selectValues, setSelectValues] = useState({
    values: [],
    isLoading: true,
  })

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const handleSelectValues = async () => {
    const res = await getInstalledApps(token)

    if (app.target_Type === 'NETWORK') {
      const { data } = await getNetworks(token)
      const valuesCopy = [...data]

      const isInstalled = res.data?.filter((el) => Number(el.appId) === Number(app.id))
      if (isInstalled.length) {
        const currentInstallations = [...isInstalled.map((el) => el.targetIds)]
        const filteredValues = valuesCopy.filter((el) => !currentInstallations.includes(el.id))
        setSelectValues((prev) => ({
          ...prev,
          values: [...filteredValues],
          isLoading: false,
        }))
      } else {
        setSelectValues((prev) => ({
          ...prev,
          values: [...data],
          isLoading: false,
        }))
      }
    }
    if (app.target_Type === 'CLIENT') {
      const a = await getInvitedUsers(token)
      const valuesCopy = [...a.data]

      const isInstalled = res.data?.filter((el) => Number(el.appId) === Number(app.id))
      if (isInstalled.length) {
        const currentInstallations = [...isInstalled.map((el) => el.targetIds)]

        const filteredValues = valuesCopy.filter((el) => {
          if (el.userId !== null) {
            return !currentInstallations.includes(el?.userId?.toString())
          }
        })
        setSelectValues((prev) => ({
          ...prev,
          values: [...filteredValues],
          isLoading: false,
        }))
      } else {
        setSelectValues((prev) => ({
          ...prev,
          values: [...a.data],
          isLoading: false,
        }))
      }
    }
    if (app.target_Type === 'BUSINESSPARTNER') {
      const a = await getInvitedBy(token)
      const valuesCopy = [...a.data]

      const isInstalled = res.data?.filter((el) => Number(el.appId) === Number(app.id))
      if (isInstalled.length) {
        const currentInstallations = [...isInstalled.map((el) => el.targetIds)]

        const filteredValues = valuesCopy.filter((el) => !currentInstallations.includes(el.userId.toString()))
        setSelectValues((prev) => ({
          ...prev,
          values: [...filteredValues],
          isLoading: false,
        }))
      } else {
        setSelectValues((prev) => ({
          ...prev,
          values: [...a.data],
          isLoading: false,
        }))
      }
    }
  }

  const installAppMutation = useMutation({
    mutationFn: async (data) => {
      const result = await trigger(['targetIds', 'parameter', 'appName'])
      if (!result) {
        return addApp(token, {
          appId: app.id,
          name: data.appName,
          targetIds: data.targetIds.toString(),
          params: data.parameter ? data.parameter : '',
        })
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['apps'])
      setAppDetails('')
    },
  })

  useEffect(() => {
    handleSelectValues()
  }, [])

  return (
    <Modal
      closeModal={(e) => {
        e.preventDefault()
        setAppDetails('')
      }}
    >
      <CloseIcon
        onClick={(e) => {
          e.preventDefault()
          setAppDetails('')
        }}
      />
      <Content>
        <Text secondary style={{ fontSize: 8, marginTop: -10 }}>
          {t('pages.apps.install-app.created-by')} {app?.vendor}
        </Text>
        <Title>{app?.title}</Title>

        <Text secondary>{app?.description}</Text>
        <form onSubmit={handleSubmit(installAppMutation.mutate)}>
          <div style={{ position: 'relative' }}>
            <Form.Label>{t('pages.apps.install-app.app-name')}:</Form.Label>
            <Form.Input name="appName" id="appName" placeholder={t('pages.apps.install-app.enter-app-name')} {...register('appName', { required: t('pages.apps.install-app.field-required') })} />
            <ErrorMessage errors={errors} name="appName" render={({ message }) => <FormError message={message} top="54px" left="0" />} />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                width: '50%',
              }}
            >
              <Form.Label>{t('pages.apps.install-app.app-target')}</Form.Label>
              <select value={app.target_Type} disabled>
                <option value="NETWORK">{t('pages.apps.install-app.network')}</option>
                <option value="CLIENT">{t('pages.apps.install-app.client')}</option>
                <option value="BUSINESSPARTNER">{t('pages.apps.install-app.business-partner')}</option>
              </select>
            </div>
            <div
              style={{
                width: '50%',
                position: 'relative',
              }}
            >
              <select
                name="targetIds"
                id="targetIds"
                {...register('targetIds', {
                  required: t('pages.apps.install-app.field-required'),
                })}
              >
                {app.target_Type === 'NETWORK' && !selectValues.isLoading && selectValues?.values?.map((value) => <option value={value.id}>{value.name}</option>)}
                {app.target_Type === 'BUSINESSPARTNER' && !selectValues.isLoading && selectValues?.values?.map((value) => <option value={value.userId}>{value.user}</option>)}
                {app.target_Type === 'CLIENT' && !selectValues.isLoading && selectValues?.values?.map((value) => <option value={value.userId}>{value.user}</option>)}
              </select>
              <ErrorMessage errors={errors} name="targetIds" render={({ message }) => <FormError message={message} top="30px" left="0" />} />
            </div>
          </div>

          <div style={{ position: 'relative' }}>
            <Form.Label>{params[0]}</Form.Label>
            <small>{params[1]}</small>
            {params[2] === 'input' && (
              <Form.Input
                id="parameter"
                name="parameter"
                placeholder={t('pages.apps.install-app.enter-data')}
                {...register('parameter', {
                  required: t('pages.apps.install-app.field-required'),
                })}
              />
            )}
            <ErrorMessage errors={errors} name="parameter" render={({ message }) => <FormError message={message} top="73px" left="0" />} />
          </div>
          <ButtonBox>
            <Button
              red
              onClick={(e) => {
                e.preventDefault()
                setAppDetails('')
              }}
            >
              {t('pages.apps.install-app.buttons.cancel')}
            </Button>
            <Button green type="submit">
              {installAppMutation.isLoading ? (
                <p>
                  {t('pages.apps.install-app.loading')}
                  <Spinner />
                </p>
              ) : (
                t('pages.apps.install-app.buttons.save')
              )}
            </Button>
            {installAppMutation.isError && t('pages.apps.install-app.error')}
          </ButtonBox>
        </form>
      </Content>
    </Modal>
  )
}

export default AppDetails
