import { useContext, useState, useRef, useEffect } from 'react'
import { AppList, AppListItem, Title, AppInfo, Tooltip, LogsContainer, LogsRow, IdBox, TypeBox, TimeBox, RecommendationBox, TitleBox } from './InstalledApps.styled'
import Card from '../../../components/Card/Card'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { deleteApp, getAppLogs, getInstalledApps } from '../../../services'
import { AuthContext } from '../../../context/AuthContext'
import { ImBin } from 'react-icons/im'
import { MdOutlineLibraryBooks } from 'react-icons/md'
import { TbApps } from 'react-icons/tb'
import Spinner from '../../../components/Spinner/Spinner'
import { BsThreeDots } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

const InstalledApps = () => {
  const [installedApps, setInstalledApps] = useState([])
  const [appLogs, setAppLogs] = useState([])
  const { t } = useTranslation()
  const [openTooltip, setOpenTooltip] = useState({
    isOpen: false,
    appId: '',
  })
  const ref = useRef(null)
  const {
    user: { token },
  } = useContext(AuthContext)

  const handleCloseTooltip = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenTooltip((prev) => ({
        ...prev,
        setOpenTooltip: false,
        appId: '',
      }))
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleCloseTooltip, true)
    return () => {
      document.removeEventListener('click', handleCloseTooltip, true)
    }
  }, [])

  const queryClient = useQueryClient()

  const installedAppsQuery = useQuery({
    queryFn: () => getInstalledApps(token),
    queryKey: ['apps'],
    onSuccess: (res) => {
      setInstalledApps([...res.data])
    },
  })

  const logsQuery = useMutation({
    mutationFn: async (app) => {
      const res = await getAppLogs(token, app)
      setOpenTooltip(false)
      setAppLogs((prev) => [...res?.data])
    },
  })

  const deleteAppMutation = useMutation({
    mutationFn: (appId) => deleteApp(token, appId),
    onSuccess: () => {
      setOpenTooltip(false)
      queryClient.invalidateQueries(['apps'])
    },
  })

  return (
    <Card margin="20px 0 0 0">
      <Title>{t('pages.apps.installed-apps')}:</Title>
      <AppList>
        {installedAppsQuery.isLoading && (
          <p style={{ display: 'flex', gap: 3 }}>
            {t('pages.apps.install-app.loading')}... <Spinner />
          </p>
        )}
        {installedAppsQuery.isError && <p>{t('pages.apps.install-app.error')}</p>}

        {installedApps.map((app) => (
          <AppListItem key={app.id}>
            <BsThreeDots
              className="see-more"
              onClick={() => {
                setOpenTooltip((prev) => ({
                  ...prev,
                  isOpen: true,
                  appId: app.id,
                }))
              }}
            />

            <TbApps />
            <AppInfo>
              <p>{app.name}</p>
            </AppInfo>
            {openTooltip.isOpen && openTooltip.appId === app.id && (
              <Tooltip ref={ref}>
                <ul>
                  <li onClick={() => deleteAppMutation.mutate(app.id)}>
                    <ImBin id="bin-icon" />
                    {t('pages.apps.remove-app')}
                  </li>
                  <li onClick={() => logsQuery.mutate(app.appId)}>
                    <MdOutlineLibraryBooks />
                    {t('pages.apps.show-logs')}
                  </li>
                </ul>
              </Tooltip>
            )}
          </AppListItem>
        ))}
        {!installedApps.length && <p>-</p>}
      </AppList>
      <LogsContainer>
        {logsQuery.isSuccess &&
          appLogs.length > 0 &&
          appLogs.map((log) => (
            <LogsRow>
              <IdBox>{log.id}</IdBox>
              <TitleBox>{log.title}</TitleBox>
              <RecommendationBox>{log.recommendation}</RecommendationBox>
              <TypeBox>{log.type}</TypeBox>
              <TimeBox>{new Date(log.time).toLocaleString()}</TimeBox>
            </LogsRow>
          ))}
      </LogsContainer>
      {logsQuery.isSuccess && !appLogs.length && <p style={{ padding: 10, fontSize: 12 }}>{t('pages.apps.no-logs')}.</p>}
    </Card>
  )
}

export default InstalledApps
