import axios from 'axios'

export async function sendInvitation(data) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Admin/RegisterInvitation?email=${data.email}`,
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_API_ADMIN_TOKEN}`,
      },
    }
  )
  return res
}

export async function changePassword(data) {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/Admin/ChangePassword?user=${data.user}&pass=${data.pass}`,
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_API_ADMIN_TOKEN}`,
      },
    }
  )
  return res
}

export async function signIn(data) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Admin/Login?email=${data.email}&pass=${data.pass}`,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_API_ADMIN_TOKEN}`,
      },
    }
  )
  localStorage.setItem('b2b_share_email', data.email)

  return res
}
