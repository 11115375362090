import { useContext, useEffect, useState } from 'react'
import { AuthContext } from './context/AuthContext'
import Loading from './pages/Loading/Loading'
import Routes from './routes/Routes'

function App() {
  const { setIsLoggedIn, setUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    const jwtToken = window.localStorage.getItem('b2b_share_token')
    const email = window.localStorage.getItem('b2b_share_email')
    console.log(jwtToken, email)
    if (jwtToken && email) {
      setIsLoggedIn(true)
      setUser({
        userName: email,
        token: jwtToken,
      })
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  return <div className="App">{isLoading ? <Loading /> : <Routes />}</div>
}

export default App
