import { Link } from 'react-router-dom'
import Navbar from '../../components/Bars/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import { Layout } from '../Home/Home.styled'
import { Container, Title, Box, BoxTitle, Section, SectionTitle } from './QuickGuide.styled'
import main_page from '../../assets/images/main_page.png'
import confirmation from '../../assets/images/xonfirmation_email.png'
import login_page from '../../assets/images/login_page.png'
import network from '../../assets/images/invitation.png'
import network2 from '../../assets/images/network_image2.png'
import invitation from '../../assets/images/network_image.png'
import apps from '../../assets/images/apps.png'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../components/Layout/PrimaryLayout/PrimaryLayout'
import MobileNavbar from '../../components/Bars/MoblieNavbar/MobileNavbar'

const QuickGuide = () => {
  const { t } = useTranslation()
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  const handleClickScroll = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Layout>
      <Navbar />
      <MobileNavbar />
      <Container>
        <Title>
          {t('pages.quick-guide.title-1')} <br />
          {t('pages.quick-guide.title-2')} <span>{t('pages.quick-guide.title-3')}</span>
        </Title>
        <Box>
          <BoxTitle>{t('pages.quick-guide.use-cases-title')}</BoxTitle>
          <div onClick={() => handleClickScroll('section-1')}>
            <h5>1.</h5>
            <p>{t('pages.quick-guide.use-cases.sign-up.title')}</p>
          </div>
          <div onClick={() => handleClickScroll('section-2')}>
            <h5>2. </h5>
            <p>{t('pages.quick-guide.use-cases.sign-in.title')}</p>
          </div>
          <div onClick={() => handleClickScroll('section-3')}>
            <h5>3. </h5>
            <p>{t('pages.quick-guide.use-cases.create-order.title')}</p>
          </div>
          <div onClick={() => handleClickScroll('section-4')}>
            <h5>4. </h5>
            <p>{t('pages.quick-guide.use-cases.read-orders.title')}</p>
          </div>
        </Box>
        <Section>
          <SectionTitle id="section-1">
            <h5>1.</h5>
            <p>{t('pages.quick-guide.use-cases.sign-up.title')}</p>
          </SectionTitle>
          <p>
            {t('pages.quick-guide.use-cases.sign-up.text-1')}
            <Link style={{ marginLeft: 5, color: 'black', textDecoration: 'none' }} to="/">
              https://b2bshare.online
            </Link>
          </p>
          <img src={main_page} />
          <p>{t('pages.quick-guide.use-cases.sign-up.text-2')} :</p>
          <img src={confirmation} />
          <p>{t('pages.quick-guide.use-cases.sign-up.text-3')}</p>
        </Section>
        <Section>
          <SectionTitle id="section-2">
            <h5>2.</h5>
            <p>{t('pages.quick-guide.use-cases.sign-in.title')}</p>
          </SectionTitle>
          <p>
            {t('pages.quick-guide.use-cases.sign-in.text-1')}
            <Link style={{ marginLeft: 5, color: 'black', textDecration: 'none' }} to="/">
              https://b2bshare.online
            </Link>
          </p>
          <img src={login_page} />
          <p>{t('pages.quick-guide.use-cases.sign-in.text-2')}</p>
          <img src={network} />
          <p>{t('pages.quick-guide.use-cases.sign-in.text-3')}</p>
          <img src={invitation} />

          <p>{t('pages.quick-guide.use-cases.sign-in.text-4')}</p>
          <img src={network2} />
        </Section>
        <Section>
          <SectionTitle id="section-3">
            <h5>3.</h5>
            <p>{t('pages.quick-guide.use-cases.create-order.title')}</p>
          </SectionTitle>
          <p>{t('pages.quick-guide.use-cases.create-order.text-1')}</p>
          <p>{t('pages.quick-guide.use-cases.create-order.text-2')}</p>
          <pre>https://api.b2bshare.online/api/Order</pre>
          <pre>
            Authorization: Bearer <br />
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9. eyJpYXQiOiIxNjgwMDk3NjUyIiwianRpIjoiMzNYWldQbk1NQiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYLy9zY2hlbWFzLm1pY3Jv
            c29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvZ3JvdXBzaWQiOiJiMmJzaGFyZS5vbmxpbmUiLCJodHRwOi8vc2NoZW1hcy5
            taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJTdXBwbGllciIsImlzcyI6Imh0dHBzOi8vYjJic2hhcmU
            ub25saW5lIiwiYXVkIjoiaHR0cHM6Ly9iMmJzaGFyZS5vbmxpbmUifQ.fsvyNUOVlOagt2X6okcmWZrOX-sRx1F_eORqj5yzvZs'
          </pre>
          <p>{t('pages.quick-guide.use-cases.create-order.text-3')}</p>
          <img src={apps} />
          <p>{t('pages.quick-guide.use-cases.create-order.text-4')}</p>
          <pre>https://api.b2bshare.online/Supplier/InvitedBy</pre>
          <pre>
            curl -X 'GET' \ 'https://api.b2bshare.online/Supplier/InvitedBy' \
            <br />
            -H 'accept: text/plain' \ -H 'Authorization: Bearer
            <br />
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOiIxNjgwMDk3NjUyIiwianRpIjoiMzNYWldQbk1NQiIsImh0dHA6Ly9zY2hlVudGl0eS9jbGFpbXMvZ3JvdXBzaWQiOiJiMmJzaGFyZS5vbmxpbmUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJTdXBwbGllciIsImlzcyI6Imh0dHBzOi8vYjJic2hhcmUub25saW5lIiwiYXVkIjoiaHR0cHM6Ly9iMmJzaGFyZS5vbmxpbmUifQ.fsvyNUOVlOagt2X6okcmWZrOX-sRx1F_eORqj5yzvZs'
          </pre>
          <pre>
            {JSON.stringify(
              [
                {
                  supplierId: 5234,
                  salesOrderNumber: 'PO 7/3/2023',
                  purchaseOrderNumber: 'PO 7/3/2023',
                  currency: 'PLN',
                  shippingAddress: {
                    companyName: 'Company Test',
                    addressLine1: 'Street1',
                    postCode: '70-111',
                    city: 'Wroclaw',
                    countryISO2: 'PL',
                    contactFirstName: 'Peter',
                    contactLastName: 'Smith',
                    contactPhone: '+44123456789',
                    contactEmail: 'hwedjsk332fbvc@alfa.com',
                    note: 'Dog',
                  },
                  billingAddress: {
                    companyName: 'Company Billing Test',
                    addressLine1: 'Skarbowcow 32',
                    addressLine2: 'Grabiszynek',
                    addressLine3: 'Next to the pizzeria',
                    postCode: '53-111',
                    city: 'Wrocław',
                    countryISO2: 'PL',
                    contactFirstName: 'Paul',
                    contactLastName: 'Smith',
                    contactPhone: '+44123456789',
                    contactEmail: 'psmith@alga.net.de',
                    note: 'test note 12',
                  },
                  orderItems: [
                    {
                      name: 'name12',
                      sku: 'sku12',
                      ean: 'ean12',
                      quantity: 2,
                    },
                    {
                      name: 'name22',
                      sku: 'sku22',
                      ean: 'ean22',
                      quantity: 2,
                    },
                  ],
                  notes: [
                    {
                      note: 'Notes to the order 1',
                    },
                  ],
                },
              ],
              null,
              2
            )}
          </pre>
        </Section>
        <Section>
          <SectionTitle id="section-4">
            <h5>4.</h5>
            <p>{t('pages.quick-guide.use-cases.read-orders.title')}</p>
          </SectionTitle>
          <p>{t('pages.quick-guide.use-cases.read-orders.text-1')} </p>
          <pre>https://api.b2bshare.online/Supplier/Orders?offset=0&limit=100</pre>
          <pre>
            curl -X 'GET' \ <br />
            'https://api.b2bshare.online/Supplier/Orders?offset=0&limit=100' \ <br />
            -H 'accept: text/plain' \ -H 'Authorization: Bearer <br />
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9. eyJpYXQiOiIxNjgwMDk4MTIzIiwianRpIjoiZFRBZk9sLW9QcyIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDkvMDkvaWRlbnRpdHkvY2xhaW1zL2FjdG9yIjoiYX
            JrYWRpdXN6LmJ1a293aWVvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJTdXBwbGllciIsImlzcyI6Imh0dHBzOi8vYjJic2hhcmUub25saW5lIiwiYXV
            kIjoiaHR0cHM6Ly9iMmJzaGFyZS5vbmxpbmUifQ.HRE1I72uaxyOoykP8YqnZoHBFyv0MY-med7z7Skm4uw'
          </pre>
          <p>{t('pages.quick-guide.use-cases.read-orders.text-2')}:</p>
          <pre>
            {JSON.stringify(
              {
                orders: [
                  {
                    id: '6',
                    customerId: 97,
                    supplierId: 5234,
                    purchaseOrderNumber: 'PO 7/3/2023',
                    salesOrderNumber: null,
                    createdAt: 1680097759,
                    currency: 'PLN',
                    shippingAddress: {
                      companyName: 'Company Test',
                      addressLine1: 'Street1',
                      postCode: '70-111',
                      city: 'Wroclaw',
                      countryISO2: 'PL',
                      contactFirstName: 'Peter',
                      contactLastName: 'Smith',
                      contactPhone: '+44123456789',
                      contactEmail: 'hwedjsk332fbvc@alfa.com',
                      note: 'Dog',
                    },
                    billingAddress: {
                      companyName: 'Company Billing Test',
                      addressLine1: 'Skarbowcow 32',
                      addressLine2: 'Grabiszynek',
                      addressLine3: 'Next to the pizzeria',
                      postCode: '53-111',
                      city: 'Wrocław',
                      countryISO2: 'PL',
                      contactFirstName: 'Paul',
                      contactLastName: 'Smith',
                      contactPhone: '+44123456789',
                      contactEmail: 'psmith@alga.net.de',
                      note: 'test note 12',
                    },
                    orderItems: [
                      {
                        id: '11',
                        name: 'name12',
                        sku: 'sku12',
                        ean: 'ean12',
                        quantity: 2,
                        unitPriceNet: null,
                        unitPriceGross: null,
                        taxRate: null,
                        totalAmountNet: null,
                        totalAmountGross: null,
                        taxAmount: null,
                        orderId: '6',
                      },
                      {
                        id: '12',
                        name: 'name22',
                        sku: 'sku22',
                        ean: 'ean22',
                        quantity: 2,
                        unitPriceNet: null,
                        unitPriceGross: null,
                        taxRate: null,
                        totalAmountNet: null,
                        totalAmountGross: null,
                        taxAmount: null,
                        orderId: '6',
                      },
                    ],
                    notes: [
                      {
                        id: '5',
                        createdAt: 0,
                        note: 'Some notes',
                        orderId: '6',
                      },
                    ],
                    extra0: null,
                    extra1: null,
                    extra2: null,
                    extra3: null,
                    extra4: null,
                    extra5: null,
                    extra6: null,
                    extra7: null,
                    extra8: null,
                    extra9: null,
                  },
                ],
                nextOffset: 1,
              },
              null,
              2
            )}
          </pre>
        </Section>
        <p>{t('pages.quick-guide.use-cases.read-orders.text-3')}</p>
      </Container>
      <Footer />
    </Layout>
  )
}

export default QuickGuide
