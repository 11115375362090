import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  display: none;
  @media (max-width: 768px) {
    background-color: #2d3250;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10px;
    height: fit-content;
    border-bottom: 1px solid #676f9d;
  }
  button {
    background-color: transparent;
    color: #f9b17a;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
`
export const Dropdown = styled.div`
  width: 100%;
`
export const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;

  .logo {
    display: flex;

    items-align: center;
    gap: 4px;
    color: white;
    font-size: 15px;
  }
  .mobile-menu {
    display: flex;
    gap: 10px;
  }
`
export const NavItem = styled.li`
  text-decoration: none;
  width: 100%;
`
export const NavLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 12px;
  color: white;
  text-decoration: none;
  font-weight: ${(props) => props.$active && '600'};
  width: 100%;
  background-color: ${(props) => props.$active && '#676f9d'};
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  svg {
    width: 25px;
    height: 25px;
  }
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #ffffffaa;
  img {
    height: 30px;
    width: 20px;
  }
  p {
    color: white;
  }
`
export const Container = styled.div`
  background-color: #2d3250 !important;
  position: fixed;
  z-index: 9999;
  left: -1px;
  top: 0px;
  padding: 30px 20px;

  width: 340px;
  display: flex;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .close-icon {
    position: absolute;
    right: 10px;
    top: 32px;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    svg {
      width: 27px;
      height: 27px;
    }
  }
`
