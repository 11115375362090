import axios from 'axios'

export async function getStats(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/Stats`,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}
