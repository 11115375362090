import { Container, MobileAside, MobileMenuIcon, ListItem, Logo, Background } from './MobileSidebar.styled'
import { TbDatabaseExport, TbApps } from 'react-icons/tb'
import { FaFileInvoiceDollar, FaNetworkWired } from 'react-icons/fa'
import { MdOutlinePayment, MdClose } from 'react-icons/md'
import { FiLogOut, FiMenu } from 'react-icons/fi'
import { useState, useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/images/logo.svg'

const MobileSidebar = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const { setUser, setIsLoggedIn } = useContext(AuthContext)

  return (
    <Container>
      <MobileMenuIcon onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
        <FiMenu className="mobile-icon" />
      </MobileMenuIcon>
      {isOpenSidebar && <Background onClick={() => setIsOpenSidebar(!isOpenSidebar)} />}
      {isOpenSidebar && (
        <MobileAside>
          <div>
            <Logo className="logo">
              <img src={logo} alt="logo" />
              <p>B2BShare.net</p>
            </Logo>
            <button className="close-icon" onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
              <MdClose />
            </button>
            <ul>
              <ListItem to="/data-centre" $active={location.pathname === '/data-centre'}>
                <TbDatabaseExport className="icon" />
                <p>{t('sidebar.data-centre')}</p>
              </ListItem>
              <ListItem to="/billing" $active={location.pathname === '/billing'}>
                <FaFileInvoiceDollar className="icon" />
                <p>{t('sidebar.billing')}</p>
              </ListItem>
              <ListItem to="/apps" $active={location.pathname === '/apps'}>
                <TbApps className="icon" />
                <p>{t('sidebar.apps')}S</p>
              </ListItem>
              <ListItem to="/network" $active={location.pathname === '/network'}>
                <FaNetworkWired className="icon" />
                <p>{t('sidebar.networks')}</p>
              </ListItem>
              <ListItem to="/payments" $active={location.pathname === '/payments'}>
                <MdOutlinePayment className="icon" />
                <p>{t('sidebar.payments')}</p>
              </ListItem>
            </ul>
          </div>
          <div>
            <ListItem
              to="/"
              onClick={(e) => {
                setUser({
                  token: '',
                  userName: '',
                })
                setIsLoggedIn(false)
              }}
            >
              <FiLogOut className="icon" />
              <p>{t('sidebar.logout')}</p>
            </ListItem>
          </div>
        </MobileAside>
      )}
    </Container>
  )
}

export default MobileSidebar
