import { Error, Icon, Text } from './Form.styled'

const FormError = ({ message, top, left, width }) => {
  return (
    <Error top={top} left={left} width={width}>
      <Icon />
      <Text>{message}</Text>
    </Error>
  )
}

export default FormError
