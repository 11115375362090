import { useTranslation } from 'react-i18next'
import { Column, Container, FooterLink } from './Footer.styled'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Column>
        <FooterLink>{t('pages.dashboard.footer.platform')}</FooterLink>
        <FooterLink>{t('pages.dashboard.footer.apps')}</FooterLink>
        <FooterLink>{t('pages.dashboard.footer.pricing-plans')}</FooterLink>
        <FooterLink>{t('pages.dashboard.footer.company')}</FooterLink>
        <FooterLink>{t('pages.dashboard.footer.contact-us')}</FooterLink>
        <FooterLink>{t('pages.dashboard.footer.privacy-policy')}</FooterLink>
      </Column>
    </Container>
  )
}

export default Footer
