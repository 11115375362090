import SecondaryLayout from '../../components/Layout/SecondaryLayout/SecondaryLayout'
import { Content, Title, ApiKey } from './Apps.styled'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import InstalledApps from './InstalledApps/InstalledApps'
import AvailableApps from './AvailableApps/AvailableApps'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

const Apps = () => {
  const { t } = useTranslation()
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])

  const {
    user: { token },
  } = useContext(AuthContext)

  return (
    <SecondaryLayout>
      <Content>
        <Title>{t('pages.apps.api-key')}:</Title>
        <ApiKey>{token}</ApiKey>
        <div
          style={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <p style={{ width: 200, fontWeight: 500 }}>{t('pages.apps.api-documentation')}: </p>
            <a
              style={{
                textDecoration: 'none',
                color: '#f9b17a',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              href="https://api.b2bshare.online/swagger/index.html"
              target="_blank"
              rel="noreferrer"
            >
              https://api.b2bshare.online/swagger/index.html
            </a>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <p style={{ width: 200, fontWeight: 500 }}>{t('pages.apps.api-endpoint')}: </p>
            <a
              style={{
                textDecoration: 'none',
                color: '#f9b17a',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              href="https://api.b2bshare.online"
              target="_blank"
              rel="noreferrer"
            >
              https://api.b2bshare.online
            </a>
          </div>
          <p
            style={{
              fontSize: 10,
              marginTop: 15,
            }}
          >
            {t('pages.apps.contact-info')}
          </p>
        </div>
      </Content>
      <InstalledApps />
      <AvailableApps />
    </SecondaryLayout>
  )
}

export default Apps
