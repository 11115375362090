import axios from 'axios'

export async function getInvitedUsers(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/InvitedUsers`,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function inviteUser(token, data) {
  let newData = {}
  if (!Object.keys(data.network).length) {
    newData = {
      ...data,
      network: null,
    }
  } else {
    newData = {
      ...data,
    }
  }
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Supplier/RegisterInvitation`,
    newData,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function getInvitedBy(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/InvitedBy`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function acceptInvitation(token, email) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Supplier/InvitationAccept?emailTo=${email}`,
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function rejectInvitation(token, email) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Supplier/InvitationReject?emailTo=${email}`,
    {},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function getNetworks(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/Networks`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function updateNetwork(token, network) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Supplier/Networks`,
    network,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}
