import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
`
export const SubmitButton = styled.button`
  background-color: #f9b17a;
  border: none;
  color: #2d3250;
  padding: 10px;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
`
