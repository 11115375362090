import { useTranslation } from 'react-i18next'
import { Container, Content, Button, Text, Separator } from './Unauthorized.styled'
import { useNavigate } from 'react-router-dom'

const Unauthorized = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Container>
      <Content>
        <Text>{t('pages.unauthorized.error-text')}</Text>
        <Button onClick={(e) => navigate('/sign-in')}>{t('pages.unauthorized.login')}</Button>
        <Separator>
          <div className="line" />
          <p>{t('pages.unauthorized.or')}</p>
          <div className="line" />
        </Separator>
        <Button onClick={(e) => navigate('/')}>{t('pages.unauthorized.get-access')}</Button>
      </Content>
    </Container>
  )
}

export default Unauthorized
