import { useContext, useState } from 'react'
import { Title, ClientList, ClientListItem } from './InvitedByUsers.styled'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AuthContext } from '../../../context/AuthContext'
import { acceptInvitation, getInvitedBy, rejectInvitation } from '../../../services'
import { BsFileCheckFill } from 'react-icons/bs'
import { ImBin } from 'react-icons/im'
import { FaUser } from 'react-icons/fa'
import Card from '../../../components/Card/Card'
import Spinner from '../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'

const InvitedByUsers = () => {
  const [inviters, setInviters] = useState([])
  const {
    user: { token },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const invitedByQuery = useQuery({
    queryKey: ['network', 'by'],
    queryFn: () => getInvitedBy(token),
    onSuccess: (res) => {
      setInviters([...res.data])
    },
  })

  const rejectInvitationMutation = useMutation({
    mutationFn: (user) => rejectInvitation(token, user),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['network'])
    },
  })

  const acceptInvitationMutation = useMutation({
    mutationFn: (user) => acceptInvitation(token, user),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['network'])
    },
  })

  return (
    <Card margin="20px 0 0 0">
      <Title>{t('pages.networks.your-connected-business-partners')}</Title>
      <ClientList>
        {invitedByQuery.isLoading && (
          <p style={{ display: 'flex', gap: 3 }}>
            Loading... <Spinner />
          </p>
        )}
        {!invitedByQuery.isLoading &&
          inviters?.map((user) => (
            <ClientListItem>
              <FaUser className="user-icon" />
              <div>
                <p>{user.user}</p>
                <BsFileCheckFill className="accept" onClick={() => acceptInvitationMutation.mutate(user.user)} />
                <ImBin className="reject" onClick={() => rejectInvitationMutation.mutate(user.user)} />
              </div>
              <input type="checkbox" />
            </ClientListItem>
          ))}
      </ClientList>
    </Card>
  )
}

export default InvitedByUsers
