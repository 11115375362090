import styled from 'styled-components'

export const Container = styled.div`
  background-color: #2d3250;
  min-height 100vh;
  display: flex;
  background-color: #f3f3f7;
  width: 100vw;

`
export const Content = styled.div`
  background-color: #f3f3f7;
  padding-left: 35px;
  padding-bottom: 35px;
  padding-right: 35px;
  margin-left: 80px;
  width: calc(100% - 80px);
  color: #2d3250;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`

export const Topbar = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-size: 14px;
  }
  .icon {
    width: 22px;
    height: 22px;
  }
`
