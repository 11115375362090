import styled from 'styled-components'

const Card = ({ children, margin }) => {
  return <Container margin={margin}>{children}</Container>
}

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  margin: ${(props) => props.margin && props.margin};
`

export default Card
