import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, user } = useContext(AuthContext)

  if (!isLoggedIn || !user.token || !user.userName) {
    return <Navigate to="/unauthorized" replace />
  }
  return children
}

export default PrivateRoute
