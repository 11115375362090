import { RiErrorWarningFill } from 'react-icons/ri'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import CreatableSelect from 'react-select/creatable'
import { RiCloseFill } from 'react-icons/ri'
import { InputField, Button, Form, Label, FormGroup, EmailChip, Chips, ErrorMessage, SuccessMessage } from './InviteUsersForm.styled'
import { inviteUser, getNetworks } from '../../../services'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import Card from '../../../components/Card/Card'
import Spinner from '../../../components/Spinner/Spinner'
import { useTranslation } from 'react-i18next'

const InviteUsersForm = () => {
  const [options, setOptions] = useState([])
  const [showResultMessage, setShowResultMessage] = useState({
    isError: false,
    isSuccess: false,
  })
  const { t } = useTranslation()
  const [userData, setUserData] = useState({
    emails: [],
    network: null,
    value: '',
  })

  const {
    user: { token },
  } = useContext(AuthContext)

  const queryClient = useQueryClient()

  const getNetworkQuery = useQuery({
    queryKey: ['networks'],
    queryFn: () => getNetworks(token),
    onSuccess: (res) => {
      const opt = res.data.map((el) => ({
        label: el.name,
        id: el.id,
      }))
      setOptions([...opt])
    },
  })

  const sendInvitationMutation = useMutation({
    mutationFn: (data) => inviteUser(token, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['network'])
      setShowResultMessage((prev) => ({
        ...prev,
        isSuccess: true,
      }))
      setTimeout(() => {
        setShowResultMessage((prev) => ({
          ...prev,
          isSuccess: false,
        }))
      }, 2000)
    },
    onError: () => {
      setShowResultMessage((prev) => ({
        ...prev,
        isError: true,
      }))
      setTimeout(() => {
        setShowResultMessage((prev) => ({
          ...prev,
          isError: false,
        }))
      }, 2000)
    },
  })

  const handleChangeEmail = (e) => {
    setUserData((prev) => ({
      ...prev,
      value: e.target.value,
    }))
  }

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault()
      let email = evt.target.value.trim()
      if (email) {
        setUserData((prev) => ({
          ...prev,
          emails: [...prev.emails, email],
          value: '',
        }))
      }
    }
  }

  const handleDeleteEmail = (e, toBeRemoved) => {
    e.preventDefault()
    setUserData((prev) => ({
      ...prev,
      emails: [...prev.emails.filter((email) => email !== toBeRemoved)],
    }))
  }

  const handleChange = (newVal, actionMeta) => {
    const { action } = actionMeta
    if (action === 'select-option') {
      setUserData((prev) => ({
        ...prev,
        network: {
          name: newVal.label,
          id: newVal.id,
        },
      }))
      return
    }
    if (action === 'create-option') {
      setUserData((prev) => ({
        ...prev,
        network: {
          name: newVal.value,
        },
      }))
      return
    }
    setUserData((prev) => ({
      ...prev,
      network: null,
    }))
  }

  async function handleSubmitForm(e) {
    e.preventDefault()
    const data = {
      email: userData?.emails?.length ? (userData?.emails?.length > 1 ? userData?.emails : userData?.emails[0]) : userData?.value,
      network: {
        ...userData.network,
      },
    }
    sendInvitationMutation.mutate(data)

    setUserData({
      emails: [],
      network: null,
      value: '',
    })
  }
  return (
    <Card margin="0px 0px 20px 0px">
      <Form onSubmit={handleSubmitForm}>
        <FormGroup
          style={{
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <Label>{t('pages.networks.invite')}: </Label>
          <InputField type="text" placeholder={t('pages.networks.invite-placeholder')} onChange={handleChangeEmail} onKeyDown={handleKeyDown} value={userData.value} />
          <Chips>
            {userData?.emails?.map((el) => (
              <EmailChip>
                {el}
                <RiCloseFill onClick={(e) => handleDeleteEmail(e, el)} />
              </EmailChip>
            ))}
          </Chips>
        </FormGroup>

        <FormGroup>
          <Label>{t('pages.networks.choose-network')}:</Label>
          <CreatableSelect className="select" isClearable options={options} onChange={handleChange} />
        </FormGroup>
        <Button>
          {sendInvitationMutation.isLoading ? (
            <p>
              {t('pages.networks.loading')}... <Spinner />
            </p>
          ) : (
            t('pages.networks.send-button')
          )}
        </Button>
      </Form>
      {showResultMessage.isError && (
        <ErrorMessage>
          <RiErrorWarningFill />
          {t('pages.networks.error')}
        </ErrorMessage>
      )}
      {showResultMessage.isSuccess && (
        <SuccessMessage>
          <AiFillCheckCircle />
          {t('pages.networks.confirmation')}
        </SuccessMessage>
      )}
    </Card>
  )
}

export default InviteUsersForm
