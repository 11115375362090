import { Title, Box, AddressContainer } from './AddressDetails.styled'
import Card from '../../../components/Card/Card'
import ClientData from './ClientData/ClientData'
import CompanyData from './CompanyData/CompanyData'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getAddress, updateAddress } from '../../../services'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AddressDetails = () => {
  const [address, setAddress] = useState({})
  const {
    user: { token },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const formMethods = useForm()

  const getAddressQuery = useQuery({
    queryKey: ['address'],
    queryFn: () => getAddress(token),
    onSuccess: (res) => {
      setAddress({ ...res.data })
    },
  })

  const updateCompanyAddressMutation = useMutation({
    mutationFn: (data) => updateAddress(token, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['address'])
    },
  })
  const updateClientAddressMutation = useMutation({
    mutationFn: (data) => updateAddress(token, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['address'])
    },
  })

  useEffect(() => {
    formMethods.setValue('companyData', address)
    formMethods.setValue('contactData', address)
  }, [getAddressQuery])

  return (
    <Card margin="20px 0">
      <Box>
        <Title>{t('pages.billing.address-details')}</Title>
        <AddressContainer>
          <CompanyData address={address} formMethods={formMethods} getAddressQuery={getAddressQuery} updateCompanyAddressMutation={updateCompanyAddressMutation} />
          <ClientData address={address} formMethods={formMethods} getAddressQuery={getAddressQuery} updateClientAddressMutation={updateClientAddressMutation} />
        </AddressContainer>
      </Box>
    </Card>
  )
}

export default AddressDetails
