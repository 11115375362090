import styled from 'styled-components'

export const Chips = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`
export const EmailChip = styled.div`
  font-size: 10px;
  background-color: #f9b17aae;
  padding: 5px 7px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  gap: 5px;
  color: #2d3250;
  font-weight: 500;
  svg {
    height: 13px;
    width: 13px;
    color: #2d3250;
    cursor: pointer;
  }
`
export const InputField = styled.input`
  border: 1px solid hsl(0, 0%, 70%);
  padding: 12px;
  width: 400px;
  font-size: 10px;
  border-radius: 3px;
  outline: none;
  @media (max-width: 768px) {
    width: 100% !important;
  }
  &:focus {
    border-color: #2d3250;
  }
`
export const Button = styled.button`
  padding: 10px;
  height: fit-content;
  width: 100px;
  color: white;
  margin-top: 28px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  background-color: #f9b17a;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`
export const Form = styled.form`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }
  .select {
    width: 200px;
    height: fit-content;
    font-size: 10px;
    outline: none;
    @media (max-width: 768px) {
      width: 100% !important;
    }
   

`
export const Label = styled.form`
  margin-bottom: 5px;
  font-size: 14px;
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 38%;
  @media (max-width: 768px) {
    min-width: 100% !important;
  }
`

export const ErrorMessage = styled.p`
  color: #ec465a;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const SuccessMessage = styled.p`
  color: #c2e812;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
`
