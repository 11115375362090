import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #2d3250;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`
export const Hero = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-width: 1500px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const Layout = styled.div`
  height: 100vh;
`

export const Content = styled.div`
  height: fit-content;
  width: 100%;
  color: white;
`

export const Form = styled.form`
  margin-top: 50px;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

export const Title = styled.h1`
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 23px;
    line-height: 35px;
    text-align: center;
  }
`
export const Subtitle = styled.h3`
  font-size: 26px;
  line-height: 42px;
  font-weight: 400;
  color: #a8aab7;
  margin-top: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
`
export const SubmitButton = styled.button`
  background-color: #f9b17a;
  border: none;
  color: #2d3250;
  margin-left: 5px;
  padding: 12px;
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 10px;
  }
`

export const FormGroup = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`
export const Label = styled.label`
  width: fit-content;
`
export const Input = styled.input`
  padding: 10px 10px;
  background-color: #676f9d;
  width: 75%;
  border: none;
  font-size: 15px;
  border-left: 4px solid #fffff00;
  &:focus {
    border: none;
    border-left: 4px solid #f9b17a;
    outline: none;
  }
  &:active {
    border: none;
    border-left: 4px solid #f9b17a;
    outline: none;
  }
  &[type='radio'] {
    width: fit-content;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    width: 75%;
  }
`

export const Group = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const GroupCheckbox = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 7px;
`

export const Right = styled.div`
  width: 40%;
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-left: 100px;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  gap: 10px;
  padding-top: 35px;
  font-weight: 300;
  font-style: italic;
  p {
    &:nth-child(2n) {
      color: #f9b17a;
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const Left = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
