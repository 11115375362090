import { Content, Title, DetailsBox, Table } from './DataCentre.styled'
import TagManager from 'react-gtm-module'
import SecondaryLayout from '../../components/Layout/SecondaryLayout/SecondaryLayout'
import { useQuery } from 'react-query'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { getStats } from '../../services'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({
    itemRank,
  })

  return itemRank.passed
}

const getCols = (t) => [
  {
    accessorKey: 'user',
    id: 'user',
    header: t('pages.data-centre.table.user'),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'orders',
    id: 'orders',
    header: t('pages.data-centre.table.orders'),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'prices',
    id: 'prices',
    header: t('pages.data-centre.table.prices'),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'stocks',
    id: 'stocks',
    header: t('pages.data-centre.table.stocks'),
    cell: (info) => info.getValue(),
  },
]

const DataCenter = () => {
  const [stats, setStats] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const { t } = useTranslation()

  const cols = getCols(t)
  const [columns] = useState([...cols])
  const [columnOrder, setColumnOrder] = useState(columns.map((column) => column.id))

  const table = useReactTable({
    data: stats,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    enableRowSelection: true,
    state: {
      columnOrder,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    enableHiding: true,
  })

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'user') {
      if (table.getState().sorting[0]?.id !== 'user') {
        table.setSorting([{ id: 'user', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  const {
    user: { token },
  } = useContext(AuthContext)

  const statisticsQuery = useQuery({
    queryKey: ['stats'],
    queryFn: () => getStats(token),
    onSuccess: (res) => {
      setStats([...res.data])
    },
  })

  return (
    <SecondaryLayout>
      <Content>
        <Title>{t('pages.data-centre.title')}</Title>
        <DetailsBox>
          <DebouncedInput value={globalFilter ?? ''} onChange={(value) => setGlobalFilter(String(value))} placeholder={`${t('pages.data-centre.search')}...`} />
          <Table>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} id="tr">
                {headerGroup.headers.map((header) => (
                  <DraggableColumnHeader key={header.id} header={header} table={table} flexRender={flexRender} />
                ))}
              </tr>
            ))}

            {statisticsQuery.isLoading && (
              <tr>
                <td colSpan={4}>{t('pages.apps.install-app.loading')}...</td>
              </tr>
            )}
            {statisticsQuery.isError && (
              <tr>
                <td colSpan={4}>{t('pages.apps.install-app.error')}</td>
              </tr>
            )}
            {!statisticsQuery.isError &&
              !statisticsQuery.isLoading &&
              stats.length > 0 &&
              table.getRowModel().rows.map((row) => {
                return <Row flexRender={flexRender} row={row} />
              })}
            {!statisticsQuery.isLoading && !statisticsQuery.isError && stats.length === 0 && (
              <tr>
                <td style={{ padding: '20px 0' }} colSpan={4}>
                  {t('pages.data-centre.table.no-users')}
                </td>
              </tr>
            )}
          </Table>
        </DetailsBox>
      </Content>
    </SecondaryLayout>
  )
}

export default DataCenter

const DraggableColumnHeader = ({ header, table, flexRender }) => {
  return (
    <th key={header.id} id="th" style={{ width: '250px' }}>
      <div>
        <div
          className="header"
          {...{
            onClick: header.column.getToggleSortingHandler(),
          }}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          <RiArrowUpDownLine id="icon" />
        </div>
      </div>
    </th>
  )
}

const Row = ({ row, flexRender }) => {
  return (
    <tr key={row.id}>
      {row.getVisibleCells().map((cell) => {
        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
      })}
    </tr>
  )
}

const DebouncedInput = ({ value: initialValue, onChange, debounce = 500, placeholder, ...props }) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} style={{ color: 'grey' }} />
}
