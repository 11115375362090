import styled from 'styled-components'

export const Title = styled.p``
export const ClientList = styled.ul`
  list-style: none;
  display: flex;
  gap: 10px;
  margin-top: 10px;
`
export const ClientListItem = styled.li`
  display: flex;
  gap: 10px;
  width: fit-content;
  padding: 15px 10px;
  border-radius: 5px;
  background: hsla(231, 28%, 25%, 1);
  background: linear-gradient(
    90deg,
    hsla(231, 28%, 25%, 1) 2%,
    hsla(231, 22%, 51%, 1) 99%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(231, 28%, 25%, 1) 2%,
    hsla(231, 22%, 51%, 1) 99%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(231, 28%, 25%, 1) 2%,
    hsla(231, 22%, 51%, 1) 99%
  );
  svg {
    height: 20px;
    width: 20px;
  }
  font-size: 10px;
  color: white;
  position: relative;
  svg.accept {
    position: absolute;
    top: 3px;
    right: 20px;
    color: #c2e812;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  p {
    margin-top: 10px;
  }
  svg.reject {
    position: absolute;
    top: 3px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    right: 2px;
    color: #ec465a;
  }
`
