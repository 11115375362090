import { Nav, NavList, Logo, NavLink, NavItem, Dropdown, Container } from './MobileNavbar.styled'
import { Background } from '../MobileSidebar/MobileSidebar.styled'
import { MdClose } from 'react-icons/md'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../../assets/images/logo.svg'

import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher'
import { FiMenu } from 'react-icons/fi'
const MobileNavbar = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Nav>
      <NavList>
        <div className="mobile-menu">
          <button onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
            <FiMenu />
          </button>
          {isOpenDropdown && <Background onClick={() => setIsOpenDropdown(!isOpenDropdown)} />}
          {isOpenDropdown && (
            <Container>
              <div>
                <Logo className="logo">
                  <img src={logo} alt="logo" />
                  <p>B2BShare.net</p>
                </Logo>
                <button className="close-icon" onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
                  <MdClose />
                </button>
              </div>
              <Dropdown>
                <NavItem>
                  <NavLink $active={location.pathname === '/'} to="/">
                    {t('pages.dashboard.navbar.home')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink $active={location.pathname === '/pricing'} to="/pricing">
                    {t('pages.dashboard.navbar.pricing')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink $active={location.pathname === '/quick_guide'} to="/quick_guide">
                    {t('pages.dashboard.navbar.quick-guide')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink $active={location.pathname === '/sign-in'} to="/sign-in">
                    {t('pages.dashboard.navbar.sign-in')}
                  </NavLink>
                </NavItem>
              </Dropdown>
            </Container>
          )}
          <div className="logo">B2BShare.net</div>
        </div>

        <LanguageSwitcher />
      </NavList>
    </Nav>
  )
}

export default MobileNavbar
