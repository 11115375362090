import { useEffect, useRef, useState } from 'react'
import PricingPlanCard from '../PricingPlanCard/PricingPlanCard'
import { Container, Carousel, Dot } from './PricingPlanCarousel.styled'
import { FaHome } from 'react-icons/fa'
import { BsTrophyFill } from 'react-icons/bs'
import { GiCutDiamond } from 'react-icons/gi'
import { FaMedal } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const PricingPlanCarousel = () => {
  const { t } = useTranslation()
  const carouselRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const PRICING_PLANS = [
    {
      title: t('pages.pricing.free'),
      subtitle: t('pages.pricing.plan1-description'),
      icon: <FaHome />,
      networks: 1,
      max_clients: 1,
      support: 'Y',
      no_sla_support: 'Y',
      sla_support: 'N',
      extra: 'N',
    },
    {
      title: `$99/${t('pages.pricing.month')}`,
      subtitle: t('pages.pricing.plan2-description'),
      icon: <BsTrophyFill />,

      networks: 1,
      max_clients: 100,
      support: 'Y',
      no_sla_support: 'Y',
      sla_support: 'N',
      extra: 'N',
    },
    {
      title: `$249/${t('pages.pricing.month')}`,
      subtitle: t('pages.pricing.plan3-description'),
      icon: <GiCutDiamond />,

      networks: t('pages.pricing.unlimited'),
      max_clients: 500,
      support: 'Y',
      no_sla_support: 'Y',
      sla_support: 'Y',
      extra: 'Y',
    },
    {
      title: `$499/${t('pages.pricing.month')}`,
      subtitle: t('pages.pricing.plan4-description'),
      icon: <FaMedal />,

      networks: t('pages.pricing.unlimited'),
      max_clients: t('pages.pricing.unlimited'),
      support: 'Y',
      no_sla_support: 'Y',
      sla_support: 'Y',
      extra: 'Y',
    },
  ]

  const handleScroll = () => {
    const scrollPosition = carouselRef.current.scrollLeft
    const newIndex = Math.round(scrollPosition / 375)
    setActiveIndex(newIndex)
  }

  return (
    <Container>
      <Carousel ref={carouselRef} onScroll={handleScroll}>
        {PRICING_PLANS.map((pricingPlan, index) => (
          <PricingPlanCard pricingPlan={pricingPlan} isActive={activeIndex === index} />
        ))}
      </Carousel>
      <div className="dots">
        <Dot isActive={activeIndex === 0} />
        <Dot isActive={activeIndex === 1} />
        <Dot isActive={activeIndex === 2} />
        <Dot isActive={activeIndex === 3} />
      </div>
    </Container>
  )
}

export default PricingPlanCarousel
