import React, { useContext } from 'react'
import Sidebar from '../../Bars/Sidebar/Sidebar'
import { Container, Content, Topbar, UserInfo } from './SecondaryLayout.styled'
import { FaUserCircle } from 'react-icons/fa'
import { AuthContext } from '../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher'
import MobileSidebar from '../../Bars/MobileSidebar/MobileSidebar'

const SecondaryLayout = ({ children }) => {
  const {
    user: { userName },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  return (
    <Container>
      <Sidebar />
      <Content>
        <Topbar>
          <MobileSidebar />
          <UserInfo>
            <FaUserCircle className="icon" />
            <p>
              {t('layout.hi')} {userName}
            </p>
          </UserInfo>
          <LanguageSwitcher dark />
        </Topbar>

        {children}
      </Content>
    </Container>
  )
}

export default SecondaryLayout
