import styled from 'styled-components'

export const Title = styled.p``
export const AppList = styled.ul`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
`
export const AppListItem = styled.li`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 10px;
  width: fit-content;
  height: 90px;
  border-radius: 5px;
  background-color: #f9b17a;
  position: relative;
  -webkit-box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);
  -moz-box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);
  box-shadow: 1px 1px 9px -4px rgba(66, 71, 105, 1);
  svg {
    height: 35px;
    width: 35px;
    color: #626997;
  }

  .see-more {
    position: absolute;
    top: 5px;
    height: 20px;
    right: 3px;
    cursor: pointer;
    color: white;
  }
`
export const AppInfo = styled.div`
  width: fit-content;
  font-size: 12px;
  color: white;
  font-weight: 600;
`

export const Tooltip = styled.div`
  width: 150px;
  background-color: #f3f3f7;
  position: absolute;
  z-index: 3;
  top: -40px;
  left: 95px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 9px 0px rgba(66, 71, 105, 1);
  -moz-box-shadow: 1px 1px 9px 0px rgba(66, 71, 105, 1);
  box-shadow: 1px 1px 9px 0px rgba(66, 71, 105, 1);
  font-size: 12px;
  font-weight: 500;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  li {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #ec465a;
    svg {
      height: 15px;
      width: 15px;
      color: #ec465a;
    }
    padding: 10px 7px;
    curosr: pointer;

    &:nth-child(2n) {
      border-top: 1px solid white;
      color: #2d3250;
      svg {
        color: #2d3250;
      }
    }
  }
`

export const LogsContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #f1f3f4;
  width: 100%;
`
export const LogsRow = styled.div`
  width: 100%;
  font-size: 14px;
  padding: 10px;
  display: flex;
  &:nth-child(2n) {
    background-color: #f1f3f4;
  }
`
export const IdBox = styled.div`
  width: 5%;
`
export const TitleBox = styled.div`
  text-align: center;
  width: 20%;
`
export const RecommendationBox = styled.div`
  width: 20%;
  text-align: center;
`
export const TypeBox = styled.div`
  width: 5%;
  text-align: center;
`
export const TimeBox = styled.div`
  width: 20%;
  text-align: right;
`
