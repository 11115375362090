import styled from 'styled-components'
import { AiFillWarning } from 'react-icons/ai'

export const Label = styled.label`
  width: fit-content;
  font-size: 12px;
`
export const Input = styled.input`
  padding: 10px;
  color: ${(props) => (props.secondary ? '#676f9df0' : 'white')};
  background-color: ${(props) => (props.secondary ? 'white' : '#676f9d')};
  border: ${(props) =>
    props.secondary ? '1px solid #676f9d' : '0px solid black'};
  width: 400px;
  font-size: 15px;
  border-left: ${(props) =>
    props.secondary ? '4px solid #676f9d' : '4px solid #ffffff00'};

  &:focus {
    outline: none;
    border: ${(props) =>
      props.secondary ? '1px solid #676f9d' : '0px solid black'};
    border-left: 4px solid #f9b17a;
  }
  &:active {
    outline: none;
    border: ${(props) =>
      props.secondary ? '1px solid #676f9d' : '0px solid black'};
    border-left: 4px solid #f9b17a;
  }
  &[type='radio'] {
    width: fit-content;
  }
`

export const Group = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
  gap: 6px;
  position: relative;
`
export const GroupCheckbox = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 7px;
`

export const SelectGroup = styled.div``

export const Text = styled.span`
  color: red;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-weight: 400;
  font-size: 11px;
`
export const Icon = styled(AiFillWarning)`
  color: red;
  width: 10px;
  height: 10px;
`

export const Error = styled.p`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3px;
  position: absolute;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  top: ${(props) => (props.top ? props.top : 0)};
  left: ${(props) => (props.left ? props.left : 0)};
`
