import Navbar from '../../components/Bars/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import { Layout } from '../Home/Home.styled'
import { Container, Header, Title, FirstPlan, SecondPlan, ThirdPlan, Content, FourthPlan } from './Pricing.styled'
import { FaHome } from 'react-icons/fa'
import { BsTrophyFill } from 'react-icons/bs'
import { GiCutDiamond } from 'react-icons/gi'
import { FaMedal } from 'react-icons/fa'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MobileNavbar from '../../components/Bars/MoblieNavbar/MobileNavbar'
import PricingPlanCarousel from '../../components/PricingPlanCarousel/PricingPlanCarousel'

const Pricing = () => {
  const { t } = useTranslation()
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  return (
    <Layout>
      <Navbar />
      <MobileNavbar />
      <PricingPlanCarousel />
      <Container>
        <Header>
          <Title>
            <span>{t('pages.pricing.title-1')}</span> <br /> {t('pages.pricing.title-2')}
          </Title>
          <FirstPlan>
            <FaHome />
            <p>{t('pages.pricing.free')}</p>
            <p>{t('pages.pricing.plan1-description')}</p>
          </FirstPlan>
          <SecondPlan>
            <BsTrophyFill />
            <p>$99/${t('pages.pricing.month')}</p>
            <p>{t('pages.pricing.plan2-description')}</p>
          </SecondPlan>
          <ThirdPlan>
            <GiCutDiamond />
            <p>$249/{t('pages.pricing.month')}</p>
            <p>{t('pages.pricing.plan3-description')}</p>
          </ThirdPlan>
          <FourthPlan>
            <FaMedal />
            <p>$499/{t('pages.pricing.month')}</p>
            <p>{t('pages.pricing.plan4-description')}</p>
          </FourthPlan>
        </Header>
        <Content>
          <div>{t('pages.pricing.number-of-networks')}</div>
          <div>1</div>
          <div>1</div>
          <div>{t('pages.pricing.unlimited')}</div>
          <div>{t('pages.pricing.unlimited')}</div>
        </Content>
        <Content>
          <div>{t('pages.pricing.number-of-customers')}</div>
          <div>1</div>
          <div>100</div>
          <div>500</div>
          <div>{t('pages.pricing.unlimited')}</div>
        </Content>
        <Content>
          <div>{t('pages.pricing.support')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
        </Content>
        <Content>
          <div>{t('pages.pricing.basic-support')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
        </Content>
        <Content>
          <div>{t('pages.pricing.premium-support')}</div>
          <div>{t('pages.pricing.no')}</div>
          <div>{t('pages.pricing.no')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
        </Content>
        <Content>
          <div>{t('pages.pricing.custom-development')}</div>
          <div>{t('pages.pricing.no')}</div>
          <div>{t('pages.pricing.no')}</div>
          <div>{t('pages.pricing.yes')}</div>
          <div>{t('pages.pricing.yes')}</div>
        </Content>
      </Container>
      <Footer />
    </Layout>
  )
}

export default Pricing
