import MobileNavbar from '../../Bars/MoblieNavbar/MobileNavbar'
import Navbar from '../../Bars/Navbar/Navbar'
import Footer from '../../Footer/Footer'
import { Container, Content } from './PrimaryLayout.styled'

const PrimaryLayout = ({ children, padding }) => {
  return (
    <Container>
      <Navbar />
      <MobileNavbar />
      <Content style={{ padding: `${padding} !important` }}>{children}</Content>
      <Footer />
    </Container>
  )
}

export default PrimaryLayout
