import styled from 'styled-components'

export const Container = styled.div`
  display: none;
  @media (max-width: 768px) {
    height: 90vh;
    padding: 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    aling-items: center;
    justify-content: center;
    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-top: 10px;
    }
  }
`

export const Dot = styled.div`
  background-color: ${(props) => (props.isActive ? '#676f9d' : '#676f9d4f')};
  border-radius: 50%;
  width: 10px;
  height: 10px;
`

export const Carousel = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-span-type: x mandatory;
  scroll-padding: 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
