import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.aside`
  background-color: #2d3250;
  height: 100vh;
  position: fixed;
  width: 80px;
  display: flex;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 10px 1px 4px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const List = styled.div`
  display: flex;
  width: 80px;
  color: white;
  flex-direction: column;
  height: 100vh;
  .flex {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ListItem = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  gap: 8px;
  background-color: ${(props) => props.$active && '#676f9d'};
  border-radius: ${(props) => props.$active && '6px'};
  border-bottom: 1px solid #ffffff0f;
  .icon {
    color: white !important;
    font-size: 20px;
  }
  p {
    color: white !important;
    font-size: 10px;
    text-align: center;
  }
`
