import SecondaryLayout from '../../components/Layout/SecondaryLayout/SecondaryLayout'
import InviteUsersForm from './InviteUsersForm/InviteUsersForm'
import InvitedUsers from './InvitedUsers/InvitedUsers'
import InvitedByUsers from './InvitedByUsers/InvitedByUsers'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'

const Network = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  return (
    <SecondaryLayout>
      <InviteUsersForm />
      <InvitedUsers />
      <InvitedByUsers />
    </SecondaryLayout>
  )
}

export default Network
