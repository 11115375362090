import { useTranslation } from 'react-i18next'
import { Container, Title, Text } from './Description.styled'
import { GoPrimitiveDot } from 'react-icons/go'
const Description = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <div>
        <Title>{t('pages.dashboard.navbar.guide-title')}</Title>
        <Text>
          <GoPrimitiveDot />
          {t('pages.dashboard.navbar.guide-1')}
        </Text>
        <Text>
          <GoPrimitiveDot />
          {t('pages.dashboard.navbar.guide-2')}
        </Text>
        <Text>
          <GoPrimitiveDot />
          {t('pages.dashboard.navbar.guide-3')}
        </Text>
        <Text>
          <GoPrimitiveDot />
          {t('pages.dashboard.navbar.guide-4')}
        </Text>
      </div>
    </Container>
  )
}

export default Description
