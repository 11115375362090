import * as Forms from '../../components/Form/Form.styled'
import * as patterns from '../../utils/patterns'
import PrimaryLayout from '../../components/Layout/PrimaryLayout/PrimaryLayout'
import FormError from '../../components/Form/FormError'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { signIn } from '../../services'
import { useMutation } from 'react-query'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Container, Form, SubmitButton } from './Login.styled'
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal'
import { htmlEncode } from '../../utils/htmlEncode'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

const Login = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      },
    })
  }, [])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setUser, setIsLoggedIn } = useContext(AuthContext)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const loginMutation = useMutation({
    mutationFn: (data) => {
      localStorage.setItem('b2b_share_email', data.email)
      setUser((prev) => ({
        ...prev,
        userName: data.email,
      }))
      return signIn({ email: htmlEncode(data.email), pass: data.pass })
    },
    onSuccess: (res) => {
      localStorage.setItem('b2b_share_token', res.data.token)
      setIsLoggedIn(true)
      setUser((prev) => ({
        ...prev,
        token: res.data.token,
      }))

      if (res.data.passwordChangeRequired) {
        setShowChangePasswordModal(true)
        return
      } else {
        navigate('/data-centre')
      }
    },
  })

  return (
    <PrimaryLayout>
      <Container>
        {showChangePasswordModal && <ChangePasswordModal />}
        <Form onSubmit={handleSubmit(loginMutation.mutate)}>
          <Forms.Group>
            <Forms.Label>{t('pages.sign-in.email')}:</Forms.Label>
            <Forms.Input
              type="email"
              placeholder={t('pages.sign-in.email-placeholder')}
              name="email"
              id="email"
              {...register('email', {
                required: t('pages.sign-in.field-required'),
                pattern: {
                  value: patterns.EMAIL_PATTERN,
                  message: t('pages.sign-in.email-validation-error'),
                },
              })}
            />
            <ErrorMessage errors={errors} name="email" render={({ message }) => <FormError message={message} top="70px" left="0" />} />
          </Forms.Group>
          <Forms.Group>
            <Forms.Label>{t('pages.sign-in.password')}:</Forms.Label>
            <Forms.Input
              type="password"
              placeholder={t('pages.sign-in.password-placeholder')}
              name="pass"
              id="pass"
              {...register('pass', {
                required: t('pages.sign-in.field-required'),
              })}
            />
            <ErrorMessage errors={errors} name="pass" render={({ message }) => <FormError message={message} top="70px" left="0" />} />
          </Forms.Group>
          <SubmitButton>{loginMutation.isLoading ? <p>{t('pages.sign-in.loading')}...</p> : t('pages.sign-in.sign-in')}</SubmitButton>
          {loginMutation.isError && (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 20px', textAlign: 'center' }}>
              <div style={{ fontSize: 10 }}>
                {t('pages.sign-in.error')}{' '}
                <Link style={{ fontWeight: 600, color: 'white' }} to="/">
                  {t('pages.sign-in.get-access')}
                </Link>
              </div>
            </div>
          )}
        </Form>
      </Container>
    </PrimaryLayout>
  )
}

export default Login
