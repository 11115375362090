import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MobileMenuIcon = styled.div`
  cursor: pointer;
  .mobile-icon {
    width: 25px;
    height: 25px;
  }
`
export const Container = styled.div`
  display: none;
  position: relative;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
  }
`
export const Background = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  height: 100vh;
  z-index: 999;
  background-color: #000000c0;
`
export const MobileAside = styled.aside`
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 0px;
  background-color: #2d3250 !important;
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  gap: 10px;
  padding: 30px 20px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 32px;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    svg {
      width: 27px;
      height: 27px;
    }
  }
`
export const ListItem = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: ${(props) => props.$active && '600'};

  background-color: ${(props) => props.$active && '#676f9d'};
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  svg {
    width: 25px;
    height: 25px;
  }
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #ffffffaa;
  img {
    height: 30px;
    width: 20px;
  }
  p {
    color: white;
  }
`
