import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Title = styled.h4`
  color: #f9b17a;
  text-align: center;
  width: fit-content;
  margin-bottom: 5px;
  font-size: 20px;
  letter-spacing: 0.6px;
  line-height: 1.5;
`

export const CloseIcon = styled(MdClose)`
  position: absolute;
  top: 15px;
  right: 10px;
  color: #ec465a;
  height: 25px;
  width: 25px;
  cursor: pointer;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  form {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 20px;
    gap: 30px;
    div {
      display: flex;
      flex-direction: column;
    }
  }
  label {
    font-size: 16px;
    margin-bottom: 5px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
  small {
    font-size: 10px;
    opacity: 60%;
    margin-bottom: 5px;
    letter-spacing: 0.6px;
    line-height: 1.5;
    @media (max-width: 768px) {
      font-size: 8px;
    }
  }
  input,
  select {
    height: 30px;
    padding: 0 5px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  select {
    width: 100%;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0 0;
  flex-direction: row !important;
  width: 100%;
`
export const Button = styled.button`
  border: none;
  color: white;
  background-color: ${(props) => props.red && '#ec465a'};
  background-color: ${(props) => props.green && '#c2e812;'};

  padding: 8px 32px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    opacity: 80%;
  }
`
export const Text = styled.p`
  color: ${(props) => (props.secondary ? 'white' : '#f9b17a')};
  font-size: ${(props) => (props.secondary ? '13px' : '16px')};
  letter-spacing: 0.6px;
  line-height: 1.5;
  font-weight: ${(props) => (props.secondary ? '300' : '600')};
  opacity: ${(props) => props.secondary && '60%'};
  @media (max-width: 768px) {
    font-size: 10px;
  }
`
