import SecondaryLayout from '../../components/Layout/SecondaryLayout/SecondaryLayout'
import Card from '../../components/Card/Card'
import { Title } from '../DataCentre/DataCentre.styled'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeForm from './StripeForm/StripeForm'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { PaymentElement } from './Payment.styled'
const Payments = () => {
  const { t, i18n } = useTranslation()
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { locale: i18n.language })
  const options = {
    appearance: {
      theme: 'stripe',
    },
  }
  return (
    <SecondaryLayout>
      <Card>
        <PaymentElement>
          <Title>{t('pages.payments.title')}</Title>
          <Link
            to="/billing"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 6,
              fontSize: '12px',
              textTransform: 'uppercase',
              textDecoration: 'none',
              backgroundColor: ' #f9b17a',
              color: 'white',
              padding: '7px 10px',
              borderRadius: 5,
            }}
            className="link"
          >
            Go to billing
            <HiArrowNarrowRight />
          </Link>
        </PaymentElement>
        <Elements stripe={stripePromise} options={options}>
          <StripeForm />
        </Elements>
      </Card>
    </SecondaryLayout>
  )
}

export default Payments
