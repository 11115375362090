import axios from 'axios'

export async function getAddress(token) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Supplier/Address`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export async function updateAddress(token, data) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/Supplier/ChangeAddress`, data, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export async function getBillingData(token) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Supplier/Billing`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export async function getAmountDue(token) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Supplier/AmountDue`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export async function getCurrentBalance(token) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Supplier/CurrentBalance`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export async function getBillingHistory(token) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Supplier/BillingHistory`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}
