import Modal from '../../../components/Modal/Modal'
import * as Forms from '../../../components/Form/Form.styled'
import FormError from '../../../components/Form/FormError'
import { SubmitButton } from '../Login.styled'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { changePassword } from '../../../services'
import { useNavigate } from 'react-router'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ChangePasswordModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showError, setShowError] = useState(false)
  const { user } = useContext(AuthContext)

  const mutation = useMutation({
    mutationFn: (data) => changePassword({ user: user.userName, pass: data.newPassword }),
    onSuccess: () => navigate('/data-centre'),
    onError: () => setShowError(true),
  })

  return (
    <Modal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: 10,
        }}
      >
        <p
          style={{
            textAlign: 'center',
            fontSize: 20,
          }}
        >
          {t('pages.change-password.title')}
        </p>
        <div
          style={{
            width: 100,
            borderBottom: '1px solid white',
            marginBottom: 10,
            height: 0,
          }}
        />
      </div>
      <form onSubmit={handleSubmit(mutation.mutate)} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <Forms.Group>
          <Forms.Label>{t('pages.change-password.new-password')}:</Forms.Label>
          <Forms.Input
            type="password"
            placeholder={t('pages.change-password.new-password-placeholder')}
            name="newPassword"
            id="newPassword"
            {...register('newPassword', {
              required: t('pages.change-password.field-required'),
            })}
          />
          <ErrorMessage errors={errors} name="newPassword" render={({ message }) => <FormError message={message} top="60px" left="0" />} />
        </Forms.Group>
        <Forms.Group>
          <Forms.Label>{t('pages.change-password.confirm-new-password')}:</Forms.Label>
          <Forms.Input
            type="password"
            placeholder={t('pages.change-password.confirm-new-password-placeholder')}
            name="confirmedNewPassword"
            id="confirmedNewPassword"
            {...register('confirmedNewPassword', {
              required: t('pages.change-password.field-required'),
            })}
          />
          <ErrorMessage errors={errors} name="confirmedNewPassword" render={({ message }) => <FormError message={message} top="60px" left="0" />} />
          {showError && <p>{t('pages.change-password.error')}</p>}
        </Forms.Group>
        <SubmitButton>{t('pages.change-password.change-password')}</SubmitButton>
      </form>
    </Modal>
  )
}

export default ChangePasswordModal
