import axios from 'axios'

export async function getApps(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Admin/Apps`,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_API_ADMIN_TOKEN}`,
      },
    }
  )
  return res
}

export async function getInstalledApps(token) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/AppConfigurations`,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function addApp(token, data) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Supplier/AppConfiguration`,
    data,

    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function deleteApp(token, id) {
  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/Supplier/AppConfiguration?id=${id}`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}

export async function getAppLogs(token, id) {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Supplier/AppLogs?appId=${id}`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return res
}
