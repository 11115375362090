import { Nav, NavList, NavItem, NavLink } from './Navbar.styled'
import { useLocation } from 'react-router-dom'
import logo from '../../../assets/images/logo.svg'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher'

const Navbar = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Nav>
      <NavList>
        <div className="logo">
          <img src={logo} alt="logo" />
          B2BShare.net
        </div>
        <div className="list">
          <NavItem>
            <NavLink $active={location.pathname === '/'} to="/">
              {t('pages.dashboard.navbar.home')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink $active={location.pathname === '/pricing'} to="/pricing">
              {t('pages.dashboard.navbar.pricing')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink $active={location.pathname === '/quick_guide'} to="/quick_guide">
              {t('pages.dashboard.navbar.quick-guide')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink $active={location.pathname === '/sign-in'} to="/sign-in">
              {t('pages.dashboard.navbar.sign-in')}
            </NavLink>
          </NavItem>
          <NavItem>
            <LanguageSwitcher />
          </NavItem>
        </div>
      </NavList>
    </Nav>
  )
}

export default Navbar
