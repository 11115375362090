import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    max-width: 1500px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 30px;
  }
`

export const Title = styled.h3`
  color: #2d3250;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`
export const Text = styled.p`
  margin-bottom: 30px;
  color: #75757b;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
  svg {
    min-height: 10px;
    min-width: 10px;
    margin-top: 7px;
    color: #f9b17a;
    margin-right: 5px;
    @media (max-width: 768px) {
      height: 8px;
      width: 8px;
      margin-top: 10px;
    }
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
